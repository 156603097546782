/*
  This Component will take in a list of table items and a list of items to filter.
  varAccess will tell the filter list what atribute to access in the list of objects
  tableItems is how you will access and display the objects in you table see model for attributes
  list is for your filter list to allow users to select items.
  valueToAccess is what will be accessed from within the filter list object
  list is for your filter list to allow users to select items.
  preSelected is an option to set a preselected object from the filter list.
  action button is the text to place on the input
  _buttonTextColor will set the color of the button text
  _buttonBackgroundColor will let the color of the button be changed
  emitList will pass the list to the parent component.
  programTriggerEmit will pass the list out when the val assigned to _emitList changes use var x != x
*/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableItemModel } from '../../core/models/table-item.model';


@Component({
  selector: 'app-display-table-with-removable-item',
  templateUrl: './display-table-with-removable-item.component.html',
  styleUrls: ['./display-table-with-removable-item.component.css']
})
export class DisplayTableWithRemovableItemComponent implements OnInit {
  tableItems = [];
  tableList = [];
  filterList;
  userSelection = {valueToAccess: '', id: null};
  userInput = '';
  showList = false;
  optionsList = [];
  buttonBackgroundColor = '#123d54';
  buttonTextColor = 'white';
  @Input() heading: string;
  @Input() varAccess: string;
  @Input() set _tableItems(tableItems: TableItemModel[]) {
    this.tableItems = tableItems;
  }
  @Input() set _list(list: any[]) {
    this.optionsList = [...list];
    this.filterList = this.optionsList .slice(0);
  }
  @Input() valueToAccess: string;
  @Input()
  set preSelected(value: string) {
    this.userInput = value;
  }
  @Input() actionButton = '';
  @Input() set _buttonTextColor(color: string) {
    this.buttonTextColor = color;
  }
  @Input() set _buttonBackgroundColor(color: string) {
    // this._buttonBackgroundColor = color;
  }
  @Input() set _emitList(val: boolean) {
    this.emitList.emit(this.tableItems.slice(0));
  }
  @Output() emitList = new EventEmitter<any>();
  constructor() { }
  @Output() programTriggerEmit = new EventEmitter<any>();

  removeItem(i) {
    const tableList = this.tableList.slice(0);
    tableList.splice(i, 1);
    this.tableList = tableList;
  }

  ngOnInit() {
  }

  userSelected(theChosen) {
    this.userInput = String(theChosen[this.valueToAccess]);
    const index = this.tableList.findIndex(tl => tl.id === theChosen.id);
    if ( index === -1) { this.tableList.push({...theChosen}); }
    this.showList = false;
  }

  filter() {
    const sampleList = this.optionsList .slice(0);
    const newFilterList = sampleList.filter(i => String(i[this.valueToAccess]).includes(this.userInput));
    if (newFilterList.length === 1) {
      this.userSelection = {...newFilterList[0]};
    }
    this.filterList = newFilterList;
  }

  confirm() {
    this.emitList.emit(this.tableList.slice(0));
  }

  mouseEnter(divA: string) {
    this.showList = true;
   }

  mouseLeave(divA: string) {
     this.showList = false;
   }

  sortTableFromIt(valuesToAccess: any) {

  }
}
