<div class="col-12 pt-2">
  <div class="row" id="filters">
    <div>
      <mat-accordion multi>
        <mat-expansion-panel [hideToggle]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>Filters</mat-panel-title>
            <mat-panel-description>
              <mat-icon>filter_list</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="">
            <div class="col-4" id="state">
              <app-multi-select-dropdown
                label="State"
                [data]="states"
                target="viewValue"
                [selectedItems]="selectedStates"
                (selection)="setState(selectedStates=$event)"
              ></app-multi-select-dropdown>
            </div>
          </div>
          <mat-action-row>
<!--            <button mat-button (click)="clearFilters()">Reset Filters</button>-->
            <button mat-button color="primary" (click)="filtersApply()">Apply Filters</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="row" id="chart-summaries">
    <div class="col-4">
      <div class="card gradient-quepal">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <i class="far fa-chart-bar fa-2x text-white"></i>
            </div>
            <div class="col ms-2 border-left-xs border-white-2">
              <h4 class="mb-0 ms-3 text-white">€{{productCategoryAverage | number: '0.2-2'}}</h4>
              <p class="mb-0 ms-3 extra-small-font text-white">Average Sales Per Product Category</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card gradient-blooker">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <i class="far fa-chart-bar fa-2x text-white"></i>
            </div>
            <div class="col ms-2 border-left-xs border-white-2">
              <h4 class="mb-0 ml-3 text-white">€{{customerAverage | number: '0.2-2'}}</h4>
              <p class="mb-0 ml-3 extra-small-font text-white">Average Revenue per Week</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card gradient-scooter">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <i class="fas fa-truck fa-2x text-white"></i>
            </div>
            <div class="col ms-2 border-left-xs border-white-2">
              <h4 class="mb-0 ml-3 text-white">€{{routeAverage | number: '0.2-2'}}</h4>
              <p class="mb-0 ml-3 extra-small-font text-white">Average Sales per Routes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="charts">
    <div class="col-4" id="product-cat-sales">
      <div class="card">
        <div class="card-header">Product Category Sales</div>
        <div class="card-body">
          <canvas
            baseChart
            [datasets]="productCategoryChartData"
            [labels]="productCategoryChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
          </canvas>
        </div>
      </div>
    </div>
    <div class="col-4" id="customer-sales">
      <div class="card">
        <div class="card-header">Customer Sales <span *ngIf="cusLoading">(Loading)</span></div>
        <div class="card-body">
          <!--            width="400" height="500"-->
          <mat-progress-bar mode="indeterminate" *ngIf="cusLoading"></mat-progress-bar>
          <canvas
            baseChart
            [datasets]="customerChartData"
            [labels]="customerChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType">
          </canvas>
        </div>
      </div>
    </div>
    <div class="col-4" id="route-sales">
      <div class="card">
        <div class="card-header">Route Sales</div>
        <div class="card-body">
          <canvas
            baseChart
            [datasets]="routeChartData"
            [labels]="routeChartLabels"
            [options]="doughnutChartOptions"
            [plugins]="barChartPlugins"
            [legend]="doughnutLegend"
            [chartType]="doughnutChartType"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>
  <div class="row" id="tables" *ngIf="!loading">
    <div class="col-4">
      <div class="card">
        <div class="card-header">Total Sales By Product Category</div>
        <div class="card-body p-0">
          <table class="table">
            <thead>
            <tr>
              <th>Product Category</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of productCategoryChartData;">
              <!--            <td [ngStyle]="{'background-color': item.borderColor}" style="font-weight:700;">{{item.label}}</td>-->
              <td style="font-weight:700;">
                <i class="fa fa-circle mr-2" [ngStyle]="{'color': item.borderColor}"></i> {{item.label}}
              </td>
              <!--            <td>{{item['total'] | number: '0.2-2'}}</td>-->
              <td>{{this.getItemTotal(item) | number}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header">Total Sales By Customer</div>
        <div class="card-body p-0">
          <table class="table">
            <thead>
            <tr>
              <th>Customer</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of customerChartData;">
              <td style="font-weight:700;">
                <i class="fa fa-circle mr-2" [ngStyle]="{'color': item.borderColor}"></i> {{item.label}}
              </td>
              <td>{{this.getItemTotal(item) | number}}</td>
              <!--            <td style="font-weight:700;">{{item['total'] | number: '0.2-2'}}</td>-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-header">Total Sales By Route</div>
        <div class="card-body p-0">
          <table class="table">
            <thead>
            <tr>
              <th>Route</th>
              <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of routeChartData[0].data; let i = index;">
              <td style="font-weight:700;">
                <i class="fa fa-circle mr-2"
                   [ngStyle]="{'color': routeChartData[0].backgroundColor[i]}"></i>{{routeChartLabels[i]}}</td>
              <td>{{item | number: '0.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
