import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

/**
 * Material Dropdown.
 * @author Daire Hardesty
 * @version 1.0.0
 * @param {string} label - The dropdown label
 * @return {string} Return a string
 * @see  {@link employee.getMessage}
 */

@Component({
  selector: 'app-material-dropdown',
  templateUrl: './material-dropdown.component.html',
  styleUrls: ['./material-dropdown.component.css']
})
export class MaterialDropdownComponent implements OnInit {
  @Input() items = [];
  @Input() selectedItem = '';
  @Input() target = '';
  @Input() label = '';
  @Input() fullWidth: boolean;
  @Output() button: EventEmitter<any> = new EventEmitter();
  @Output() selection = new EventEmitter();
  btnCondition = true;

  constructor(
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.logger.info('selectedItem', this.selectedItem);
  }

  itemSelected(item) {
    this.logger.info('item', item);
    if (this.target !== '') {
      this.logger.info(`Setting state to ${item.value[this.target]}`);
      // this.selectedItem = this.selectedItem[this.target];
      this.logger.info('selectedItem', this.selectedItem);
    } else {
      this.logger.info(`Setting state to ${item}`);
    }
    const selectedItem = item.value;
    this.selection.emit(selectedItem);
    this.logger.info('selectedItem', this.selectedItem);
    this.logger.info('item', item);
  }
}
