module.exports = {
    sales: {
        dockets: {
            dockets: false,
            docket_reprint: false
        }
    },
    orders: {
        standing_orders: false,
        special_orders: false,
        service_orders: false
    },
    production: {
        production_list: false,
        create_production_list: false,
        production_groups: false,
        production_inputs: false,
        production_process: false,
        batch_control: false
    },
    stock: {
        stock_search: {
            production_location: false,
            location_search: false,
            storage_area_search: false
        }
    },
    logistics: {
        suppliers: {
            suppliers_list: false
        },
        delivery: {
            delivery_lists: false,
            create_delivery_list: false
        },
        dispatch: {
            dispatch_groups: false,
            create_dispatch_lists: false,
            edit_dispatch_lists: false
        }
    },
    finance: { 
        documents: {
            create_invoice: false,
            view_invoice: false,
            create_statements: false,
            create_credit_notes: false
        },
        discounts: {
            product_discounts: false,
            customer_discounts: false,
            product_quantity_offers: false,
            bulk_discounts: false,
            billing_discounts: false
        },
        exports: {
            export_invoices: false,
            review_invoices: false,
            customer_exports: false,
            product_exports: false,
            edi_exports: false,
            custom_exports: false
        }
    },
    customers: {
        customers: false,
        customer_group_update: false,
        customers_on_routes: false,
        customer_specific_prices: false,
        customer_specific_price_group_update: false,
        inactive_customers: false
    },
    services: {
        service_list: false,
        create_service_form: false
    },
    products: {
        products: false,
        multiple_product_update: false,
        price_list: false,
        multiple_price_update: false,
        inactive_products: false 
    },
    admin: {
        organisation_settings: {
            company_details: false,
            billing_details: false,
            logo_upload: false
        },
        financial_settings: {
            bank_details: false,
            account_details: false,
            vat_rates: false
        },
        customer_settings: {
            depots: false,
            customer_types: false,
            routes: false,
            billing_frequency: false
        },
        product_settings: {
            product_categories: false,
            sub_product_categories: false,
            quantifiers: false,
            create_nominal_codes: false
        },
        storage_settings: {
            storage_locations: false,
            storage_location_types: false,
            storage_areas: false,
            storage_area_types: false
        },
        handheld_settings: {
            create_handhelds: false,
            assign_handhelds_to_routes: false,
            user_controls: false
        },
        other_settings: {
            process: false,
            edi_settings: false,
            commissions: false
        }
    },
    traceability: {
        barcodes: false
    },
    reports: {
        sales_reports: {
            create: false,
            saved: false,
            comparison: false,
            summary: false
        },
        production_reports: {
            create: false,
            saved: false,
            comparison: false,
            summary: false
        },
        servicing_reports: {
            create: false
        },
        stock_reports: {
            create: false
        },
        financial_reports: {
            create: false
        },
        agent_commission_report: {
            create: false,
            saved: false,
            comparison: false,
            summary: false
        }
    },
    dashboard: {
        sales: false
    }


}