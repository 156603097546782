import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-multiple-selection-table',
  templateUrl: './multiple-selection-table.component.html',
  styleUrls: ['./multiple-selection-table.component.css']
})
export class MultipleSelectionTableComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
