import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {SalestrackApiConfService} from './salestrackServiceV2/salestrackApi-conf.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  postgrestApiService = new SalestrackApiConfService();

  // subscription: Subscription;

  organisationId: number;
  API_URL = environment.API_URL;

  // Do not use - EDI_URL = 'https://jezmgqxl6h.execute-api.eu-west-1.amazonaws.com/default/celtrino-service-dev';
  // Do not use - EDI_URL = 'https://1eou8j4nc1.execute-api.eu-west-1.amazonaws.com/default/celtrino-service-mst';
  // EDI_URL = https://xjt4dxpclcowumavxvcbsx6swe0ungge.lambda-url.eu-west-1.on.aws/';
  // EDI_URL = 'https://hei5my4jc7mjbslxgz7klvu67u0jdety.lambda-url.eu-west-1.on.aws/';
  EDI_URL = 'https://hei5my4jc7mjbslxgz7klvu67u0jdety.lambda-url.eu-west-1.on.aws/';


  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'count=exact'
      }),
      observe: 'response' as 'body'
    };
  }

  getURLEncodedHttpOptions(token) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': token,
      })
    };
  }

  // Http Options for Stored Procedure calls
  getSpHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'params=single-object'
      }),
      observe: 'response' as 'body'
    };
  }

  getSpExportHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token')
      }),
      observe: 'response' as 'body'
    };
  }

  getInsertHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'count=exact, return=headers-only'
      }),
      observe: 'response' as 'body'
    };
  }

  getHttpOptionsForCSVExports() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'params=single-object'
      }),
      responseType: 'text' as 'json',
    };
  }

  serverAddress() {
    return this.API_URL;
  }

  devServerAddress() {
    return 'https://hh-api-dev.srv.handheld.ie/hhdb_api/';
  }

  masterServerAddress() {
    return 'https://hh-api-mst.srv.handheld.ie/hhdb_api/';
  }

  getServerAddress() {
    // return this.postgrestApiService.getServerAddress();
    // return 'https://hh-api-mst.srv.handheld.ie/hhdb_api/';
    return 'https://hh-api-dev.srv.handheld.ie/hhdb_api/';
  }

  getOwnerId() {
    return JSON.parse(localStorage.getItem('organisation_id'));
  }
}
