import {Component, OnInit} from '@angular/core';
import {AuthService} from '../core/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-login-alert',
  templateUrl: './login-alert.component.html',
  styleUrls: ['./login-alert.component.css']
})
export class LoginAlertComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authS: AuthService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {
    if (this.authS.isAuthenticated() === true) {
      this.router.navigate(['../dashboard'], {relativeTo: this.route});
    }
  }
}
