import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {GetApiReply} from '../models/get-api-reply.model';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';
import {Frequencytype} from '../models/dbModels/frequencytype.model';
import {pluck} from 'rxjs/operators';
import {BillingReference} from '../models/dbModels/billing-reference.model';
import {Vatrate} from '../models/dbModels/vatrate.model';
import roundHalfEven from 'round-half-even';
import {Observable} from 'rxjs';
import {SavedReport} from '../models/dbModels/savedReport.model';
import {Product} from '../models/dbModels/product.model';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  reportToBeSaved: SavedReport;
  customerToBeSaved = '';
  customerCategoryToBeSaved = '';
  productToBeSaved = '';
  productCategoryToBeSaved = '';
  routeToBeSaved = '';
  currencyToBeSaved = '';
  productToCustomerCache = 0;
  products: Product[] = [];
  productToCustomer = [];
  vatRates = [];
  customers = [];
  editItems = [];
  deleteItems = [];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private conf: ConfigService
  ) {
  }

  /** Contents *
   * Email Billing 
   * Agent commissions
   * Banks
   * Bank Accounts
   * Currencies
   * Discounts
   * - Customer
   * - Product
   * Dockets
   * Exports
   * - EDI
   * Invoices
   * Statements
   *********/

  /** Email Billing */
  execNewJsonExternalServices(parameters) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/new_json_external_services`,
      parameters,
      this.conf.getSpHttpOptions()
    );
  }

  getDocumentTypes(serviceId) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_queues?select=message_type&es_service_id=eq.${serviceId}&order=message_type`,
      this.conf.getHttpOptions()
    );
  }

  getEmailServiceId() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_services?select=id&name=eq.email_service`,
      this.conf.getHttpOptions()
    );
  }

  /** Agent Commissions */

  getDeliveryAgents(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updateDeliveryAgent(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  // uses the round-half-even npm library to achieve bankers rounding.
  // can be found at https://www.npmjs.com/package/round-half-even
  roundNumber(number: number, decimalPlaces: number) {
    if (number === null) {
      number = 0;
    }
    const minus = number < 0;
    number = minus ? number * -1 : number;
    let returnNumber = roundHalfEven(number, decimalPlaces);
    returnNumber = minus ? returnNumber * -1 : returnNumber;
    // console.log(returnNumber);
    return returnNumber;
  }

  // Commissions

  getAgentCommissions(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createAgentCommission(item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateAgentCommission(commission, id) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?id=eq.${id}`,
      commission,
      this.conf.getHttpOptions()
    );
  }

  deleteAgentCommission(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Customer commissions

  getAgentCustomerCommissions(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createAgentCustomerCommission(item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateAgentCustomerCommission(commission, id) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?id=eq.${id}`,
      commission,
      this.conf.getHttpOptions()
    );
  }

  deleteAgentCustomerCommission(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  deleteAgentCustomerCommissions(ids) {
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?id=in.(${ids})`,
      this.conf.getHttpOptions()
    );
  }

  // Product commissions

  getAgentProductsCommission(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_customers?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createAgentProductCommission(item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_products?`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateAgentProductCommission(commission, id) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_products?id=eq.${id}`,
      commission,
      this.conf.getHttpOptions()
    );
  }

  getBillingTransactionsByBillingIds(billingId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings/calculated?id=${billingId}`,
      this.conf.getHttpOptions()
    );
  }

  // Transaction Commissions

  updateCommission(commission) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_commissions?id=eq.${commission.id}`,
      commission,
      this.conf.getHttpOptions()
    );
  }

  deleteCommission(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_commissions?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  getCommissionsForTransactionsBasedOnProductIdsNew(agentId: number, productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/commission_percentages?product_id=[${productIds}]&customer_id=${customerId}` +
      `&delivery_agent_id=${agentId}&$exclude_columns=nested`,
      this.conf.getHttpOptions()
    );
  }

  getCommissionsForTransactionsBasedOnProductIds(agentId: number, productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/commission_percentages?product_id=[${productIds}]&customer_id=${customerId}` +
      `&delivery_agent_id=${agentId}&$exclude_columns=nested`,
      this.conf.getHttpOptions()
    );
  }

  getCommissionTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}commission_calculation_types?${optionalParams}}`,
      this.conf.getHttpOptions()
    );
  }

  // Agents

  createAgent(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateAgent(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  /** Banks */

  // Banks
  getBanks(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}banks?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createBank(newBank) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}banks`,
      newBank,
      this.conf.getHttpOptions(),
    );
  }

  updateBank(bankUpdate, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}banks?id=eq.${id}`,
      bankUpdate,
      this.conf.getHttpOptions(),
    );
  }

  // Bank Accounts
  getBankAccounts(optionalParams = '')  {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createBankAccount(newAccount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts`,
      newAccount,
      this.conf.getHttpOptions(),
    );
  }

  updateBankAccount(updatedAccount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?id=eq.${id}`,
      updatedAccount,
      this.conf.getHttpOptions(),
    );
  }

  deleteBankAccount(id: number) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Billing Details */

  // Billings

  getBillings(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createBillings(provisional_billings) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/create_billings`,
      provisional_billings,
      this.conf.getSpHttpOptions()
    );
  }

  updateBillings(updated_billing, ids) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}billings?id=in.(${ids})`,
      updated_billing,
      this.conf.getHttpOptions()
    );
  }

  deleteBillings(id) {
    if (isNaN(id)) {
      return null;
    }
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  createCreditNote(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/create_credit_note`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  deleteCreditVoucher(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/delete_credit_voucher`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  // Billing references

  getBillingReferences(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBillingReference(billing: BillingReference[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_references`,
      billing,
      this.conf.getHttpOptions()
    );
  }

  updateBillingReference(updated_billing, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?id=eq.${id}`,
      updated_billing,
      this.conf.getHttpOptions()
    );
  }

  getBillingDetails(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updateBillingDetails(billing: BillingReference[]) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_references?id=eq.${billing['id']}`,
      billing,
      this.conf.getHttpOptions()
    );
  }

  // Billing types

  getBillingTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Provisional billings

  getProvisionalBillings(params, optionalParams) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}rpc/provisional_billings?${optionalParams}`,
      params,
      this.conf.getSpHttpOptions()
    );

  }

  createProvisionalBillings(params, optionalParams) {
    // if (docket_ids !== null) {
    //   docket_ids = docket_ids.filter(function(item) {
    //     return !isNaN(item);
    //   });
    // }
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/provisional_billings?${optionalParams}`,
      params,
      this.conf.getSpHttpOptions()
    );
  }

  /** Billing Frequencies */

  getBillingFrequencies(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  getBillingFrequencyTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_frequency_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBillingFrequency(freq) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies`,
      freq,
      this.conf.getHttpOptions()
    );
  }

  updateBillingFrequency(freq: Frequencytype) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?id=eq.${freq.id}`,
      freq,
      this.conf.getHttpOptions()
    );
  }

  deleteBillingFrequency(id: number) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Currencies */

  getCurrencies(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}currencies?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  deleteCurrencies(id) {
    if (isNaN(id)) {
      return null;
    }
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}currencies?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Customer Specific Products */
  getCustomerProducts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_products?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createCustomerProduct(CustomerProduct) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_products`,
      [CustomerProduct],
      this.conf.getHttpOptions()
    );
  }

  updateCustomerProduct(CustomerProduct, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_products?id=eq.${id}`,
      CustomerProduct,
      this.conf.getHttpOptions()
    );
  }

  deleteCSP(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_products?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }


  /** Customer Specific Prices */
  getCustomerProductPrices(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_product_prices?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createCustomerProductPrice(CustomerProduct) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_product_prices`,
      [CustomerProduct],
      this.conf.getHttpOptions()
    );
  }

  updateCustomerProductPrice(CustomerProduct, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_product_prices?id=eq.${id}`,
      CustomerProduct,
      this.conf.getHttpOptions()
    );
  }

  deleteCustomerSpecificPrice(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_product_prices?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Discounts*/

  // Billing discounts

  getBillingDiscounts(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createBillingDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateBillingDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteBillingDiscount(ids) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts?id=in.(${ids})`,
      this.conf.getHttpOptions()
    );
  }

  // Buy X get Y free

  getBuyXGetYFreeDiscounts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}buy_x_get_y_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBuyXGetYFreeDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}buy_x_get_y_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateBuyXGetYFreeDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}buy_x_get_y_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteBuyXGetYFreeDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}buy_x_get_y_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Bulk Discounts

  getBulkDiscounts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}bulk_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBulkDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bulk_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateBulkDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bulk_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteBulkDiscount(ids) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bulk_discounts?id=in.(${ids})`,
      this.conf.getHttpOptions()
    );
  }

  // Customer discounts

  getCustomerDiscounts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createCustomerDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateCustomerDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteCustomerDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Product discounts

  getProductDiscounts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}product_discounts?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  getDiscountTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}discount_types?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

  createProductDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateProductDiscount(discount) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_discounts?id=eq.${discount.id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteProductDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  getDocketDiscounts(customerId: number, productId: number): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}docket_discounts?customer_id=eq.${customerId}&product_id=eq.${productId}`,
      this.conf.getHttpOptions()
    );
  }

  getDiscounts(customerId: number, productId: number): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}docket_discounts?customer_id=eq.${customerId}&product_id=eq.${productId}`,
      this.conf.getHttpOptions()
    );
  }

  getCustomerSpecialPrices(customerId): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_products?customer_id=eq.${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  // customer_payment_discounts

  getCustomerPaymentDiscounts(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_payment_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createCustomerPaymentDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_payment_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateCustomerPaymentDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_payment_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteCustomerPaymentDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_payment_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Dockets */


  /** Exports */

  getExports(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getExportSubscriptions(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_subscriptions?${optionalParams}`,
      this.conf.getSpHttpOptions()
    );
  }

  updateExportSubscriptions(id, payload) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}es_subscription_queues?id=eq.${id}`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  getCustomerSubscriptions(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_customer_subscriptions?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getSubscriptionQueueCustomers(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_subscription_queue_customers?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  setExportedDate(ids, date) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${ids}`,
      {export_date: date},
      this.conf.getHttpOptions()
    );
  }

  setExportedDateToNull(ids) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${ids}`,
      {export_date: null},
      this.conf.getHttpOptions()
    );
  }

  getStockReportData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/batch_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  // EDI Profiles

  getEdiProfiles(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}edi_profiles?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createEdiProfile(profile) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}edi_profiles`,
      profile,
      this.conf.getHttpOptions()
    );
  }

  updateEdiProfile(profile, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}edi_profiles?id=eq.${id}`,
      profile,
      this.conf.getHttpOptions()
    );
  }

  exportEdiProfile(profile) {
    return this.http.post<PostPutDelApiReply>(
      this.conf.EDI_URL,
      profile
    );
  }

  getEdiInvoices(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/es_get_edi_invoices`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Invoices */

  getJsonInvoices(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/new_json_invoices`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  getJsonInvoicesOld(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_invoices`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Payments */
  // Payments

  getPayments(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}payments?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createPayment(payment) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}payments`,
      payment,
      this.conf.getHttpOptions()
    );
  }

  updatePayments(updated_product, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}payments?id=eq.${id}`,
      updated_product,
      this.conf.getHttpOptions()
    );
  }

  deletePayments(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}payments?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Payment Types

  getPaymentTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}payment_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  assignPaymentToInvoice(assignment: { billing_id: any; payment_id: any }[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}payments_to_invoices`,
      assignment,
      this.conf.getHttpOptions()
    );
  }

  assignPaymentToCreditNote(assignment: { payment_id: any; credit_note_billing_id: any }[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}financials/payments_to_credit_notes`,
      assignment,
      this.conf.getHttpOptions()
    );
  }

  // Payments to billings

  getPaymentToBillings(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}payments_to_billings?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createPaymentToBilling(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}payments_to_billings`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updatePaymentToBillings(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}payments_to_billings?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deletePaymentToBillings(id) {
    id = id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}payments_to_billings?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  assignCreditToInvoice(assignments) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}financials/credit_notes_to_invoices`,
      assignments,
      this.conf.getHttpOptions()
    );
  }

  /** Statements */
  newJsonStatements(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/new_json_statements`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }
  getJsonStatements(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_statements`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  exportBase64(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/es_enqueue`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Reports */
  getReportData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/transaction_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }
  exportReportData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/transaction_reports`,
      payload,
      this.conf.getSpExportHeaders()
    );
  }

  getSavedReports(optionalParams: string) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}saved_reports?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** Transactions */
  // Discounts
  updateDiscount(discount) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_discounts?id=eq.${discount.id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Transactions
  createTransaction(transactions) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}rpc/docket_creation`,
      { 'dockets': transactions},
      this.conf.getSpHttpOptions()
    );
  }

  updateTransaction(transaction) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transactions?id=eq.${transaction.id}`,
      transaction,
      this.conf.getHttpOptions()
    );
  }

  getReturnsForTransactionsBasedOnProductIds(productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/returns_percentages?product_id=[${productIds}]&customer_id=${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  getReturnsForTransactionsBasedOnProductIdsNew(productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/returns_percentages?product_id=in.(${productIds})&customer_id=eq.${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  /** Vat Rates */

  getVatRates(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}vat_rates?${optionalParams}&owner=eq.${this.conf.getOwnerId()}`,
      this.conf.getHttpOptions()
    );
  }

//    return this.http.post<GetApiReply>(
  createVatRate(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}vat_rates`,
      item,
      this.conf.getHttpOptions()
    );
  }


  updateVatRate(vatRate: Vatrate[]) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}vat_rates?id=eq.${vatRate['id']}`,
      vatRate,
      this.conf.getHttpOptions()
    );
  }

  getDashboardData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/dashboard`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  setSavedReport(report, customer, cusCategory, product, prodCategory, route, currency) {
    this.reportToBeSaved = report;
    this.customerToBeSaved = customer;
    this.customerCategoryToBeSaved = cusCategory;
    this.productToBeSaved = product;
    this.productCategoryToBeSaved = prodCategory;
    this.routeToBeSaved = route;
    this.currencyToBeSaved = currency;
  }

  getSavedReport() {
    return {
      report: this.reportToBeSaved,
      customer: this.customerToBeSaved,
      cusCategory: this.customerCategoryToBeSaved,
      product: this.productToBeSaved,
      prodCategory: this.productCategoryToBeSaved,
      route: this.routeToBeSaved,
      currency: this.currencyToBeSaved
    };
  }

  createSavedReports(report) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}saved_reports`,
      [report],
      this.conf.getHttpOptions()
    );
  }

  saveReport(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/transaction_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  createEsSubscriptionQueueCustomer(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}es_subscription_queue_customers`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  createEsSubscriptionQueueProperty(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}es_subscription_queue_properties`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  updateEsSubscriptionQueueProperty(payload) {
    return this.http.patch(
      `${this.conf.getServerAddress()}es_subscription_queue_properties?id=eq.${payload.id}`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  removeEsSubscriptionQueueCustomer(id) {
    return this.http.delete(
      `${this.conf.getServerAddress()}es_subscription_queue_customers?id=eq.${id}`,
      this.conf.getSpHttpOptions()
    );
  }

  removeEsSubscriptionQueueCustomers(ids) {
    return this.http.delete(
      `${this.conf.getServerAddress()}es_subscription_queue_customers?id=in.(${ids})`,
      this.conf.getSpHttpOptions()
    );
  }

  getEsSubscriptionQueue(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_subscription_queues?${optionalParams}`,
      this.conf.getSpHttpOptions()
    );
  }

  getEsEntities(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}es_entries?${optionalParams}`,
      this.conf.getSpHttpOptions()
    );
  }

  getDockets(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dockets?${optionalParams}`,
      this.conf.getSpHttpOptions()
    );
  }

  getInvoices(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings?${optionalParams}`,
      this.conf.getSpHttpOptions()
    );
  }

  eSEnqueue(payload) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}/rpc/es_enqueue`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  xeroGetToken(params, authToken) {
    return this.http.post(
      'https://identity.xero.com/connect/token',
      params,
      this.conf.getURLEncodedHttpOptions(authToken)
    );
  }

  getAccountingNominalCodes(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}accounting_nominal_codes?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** Depreciated */

  updateProductToCustomerCache() {
    this.productToCustomerCache = Date.now();
  }

  checkProductToCustomerCacheCache() {
    return this.productToCustomerCache < Date.now() - 20000;
  }

  getLocalProducts() {
    return [...this.products];
  }

  getLocalVatRates() {
    return [...this.vatRates];
  }

  getLocalCustomers() {
    return [...this.customers];
  }

  getLocalProductToCustomer() {
    return [...this.productToCustomer];
  }

  setLocalProductToCustomer(pc) {
    this.productToCustomer = [...pc];
  }

  addLocalProductToCustomer(pc) {
    this.productToCustomer.push(pc);
  }

  getPriceListName() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}price_list_types`,
      this.conf.getHttpOptions()
    );
  }

  deletePriceListName(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}price_list_types/${id}`,
      this.conf.getHttpOptions()
    );
  }

  createSpecialTermsDiscount(specialTermDiscount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts`,
      specialTermDiscount,
      this.conf.getHttpOptions()
    );
  }

  updateSpecialTermsDiscount(specialTermDiscount) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts/${specialTermDiscount.id}`,
      specialTermDiscount,
      this.conf.getHttpOptions()
    );
  }

  deleteSpecialTermsDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_billing_discounts?id=[${id}]`,
      this.conf.getHttpOptions()
    );
  }

  getPriceByCurrency(
    productName: string,
    productCode: string,
    pageIndex: number,
    currencyId: number
  ): Observable<GetApiReply> {
    let string = ``;
    if (productName !== '') {
      string += `product.name=*${productName}*&`;
    }
    if (productCode !== '') {
      string += `product.code=*${productCode}*&`;
    }
    string = string.substr(0, string.length - 1);
    const httpHeaders = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}product_prices?${string}&$page=${pageIndex}&$per_page=30&currency_id=${currencyId}`,
      httpHeaders
    );
  }

  getCustomerTypes() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_types`,
      this.conf.getHttpOptions()
    ).pipe(pluck('data'));
  }

  getProductCategories() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}product_categories`,
      this.conf.getHttpOptions()
    ).pipe(pluck('data'));
  }
}
