import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {TestingComponent} from './testing.component';

const testingRoutes: Routes = [
  {path: '', component: TestingComponent}
  // {path: '', component: MenuComponent}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(testingRoutes)
  ]
})
export class TestingRoutingModule {
}
