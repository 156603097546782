import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
  selector: 'app-material-autocomplete',
  templateUrl: './material-autocomplete.component.html',
  styleUrls: ['./material-autocomplete.component.css']
})
export class MaterialAutocompleteComponent implements OnInit, OnChanges {
  @Input() label = ''; // Form label
  @Input() items = []; // Array of data
  @Input() target = ''; // Child value
  @Input() disabled: boolean; // Child value
  @Input() readOnly: boolean; // Child value
  @Input() required: boolean; // Child value
  @Input() formCheck: boolean; // Child value
  @Input() selectedItem = ''; // Item selected by user
  @Output() selection = new EventEmitter(); // Emitter to send the data back to the user
  @Output() clear = new EventEmitter(); // Emitter to send the data back to the user
  myControl: FormControl = new FormControl();
  allOptions: string[] = this.items; // there are thousands of options
  filteredOptions: Observable<string[]>; // Array of filtered data

  constructor(
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    if (this.required) {
      this.logger.log('required', this.required);
      this.myControl.setValidators(Validators.required);
    }
    // If items are empty
    if (!this.items.length) {
      // this.logger.log('No items loaded', this.items);
    }
    // if (this.items.length) {
    //   // Initialise the filter
    //   // this.logger.log('Items loaded', this.items);
    //   this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), debounceTime(500), map(val => this.filter(val)));
    //   // this.logger.log('filteredOptions', this.filteredOptions);
    // }

    setTimeout(() => {
      this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), debounceTime(500), map(val => this.filter(val)));
    }, 500);
  }

  ngOnChanges() {
    if (this.formCheck) {
      this.myControl.markAsTouched();
    }
    if (this.disabled) {
      // this.myControl.disabled;
      this.myControl.disable();
    } else {
      // Enable dropdown
      this.myControl.enable();
      // Add dropdown items
      this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), debounceTime(500), map(val => this.filter(val)));
    }
  }

  filter(val: string): string[] {
    // this.logger.log('items', this.items);
    if (val !== null && val !== undefined) {
      if (this.target !== null) {
        return this.items.filter(option => option[this.target].toLowerCase().indexOf(val.toLowerCase()) === 0);
      } else {
        return this.items.filter(option => option.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
      }
    }
  }

  // Output selected item back to component
  itemSelected(event: MatOptionSelectionChange, item) {
    this.logger.log('item', item);
    if (event.source.selected) {
      if (this.target === '') {
        this.logger.log('item', item, 'selected');
      } else {
        this.logger.log(item[this.target], 'selected');
      }
      this.selection.emit(item);
    }
  }

  clearInput() {
    this.selectedItem = '';
    this.logger.log('Cleared');
    this.clear.emit(true);
  }

}
