<!--<mat-card>-->
<!--  <mat-card-content>-->
    <mat-form-field appearance="outline">
      <mat-label *ngIf="items.length != 0">{{label}}</mat-label>
      <mat-label *ngIf="items.length == 0">
        <div class="select-placeholder-container">
          <span>Loading {{label}}s...
          <mat-spinner class="spinner" diameter="20"></mat-spinner></span>
        </div>
      </mat-label>
      <input type="text" matInput [(ngModel)]="selectedItem" [formControl]="myControl" [matAutocomplete]="auto"
             [disabled]="disabled || items.length == 0" [readonly]="items.length == 0 || readOnly" [required]="required">
      <mat-error *ngIf="myControl.invalid">{{label}} is required</mat-error>
      <button *ngIf="selectedItem && !readOnly" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option[target]"
                    (onSelectionChange)="itemSelected($event, option)" [disabled]="disabled || items.length == 0">
          <span *ngIf="target == ''">{{option}}</span>
          <span *ngIf="target != ''">{{option[target]}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
<!--  </mat-card-content>-->
<!--</mat-card>-->
