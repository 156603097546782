import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, map, startWith, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label = '';

  /** list of items */
  @Input() data = [];

  // Nested value
  @Input() target = '';

  @Input() singleSelection: boolean; // Hide the 'Select All' toggle
  @Input() selectedItems = [];
  @Input() disabled: boolean; // Disables the form

  /** control for the selected item for multi-selection */
  public itemCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public itemFilterCtrl: FormControl = new FormControl();

  /** list of items filtered by search keyword */
  public filteredItems: ReplaySubject<Item[]> = new ReplaySubject<Item[]>(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  @Output() selection = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

    setTimeout(() => {
      // Add timeout to give the object time to populate
      // console.log('Data:', this.data);
      // load the initial item list
      this.filteredItems.next(this.data.slice());
      // console.log('filteredItems:', this.filteredItems);
      // console.log('filteredItems:', this.filteredItems.asObservable());
      // this.subject.asObservable()
    }, 500);

    // listen for search field value changes
    this.itemFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterItems();
      });
  }

  ngOnChanges() {
    // console.log('Data:', this.data);
    // console.log('filteredItems:', this.filteredItems);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterItems() {
    if (!this.data) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredItems.next(
      this.data.filter(a => a[this.target].toLowerCase().indexOf(search) > -1)
    );

  }

  itemSelected(item) {
    // console.log('Selected item:', item);
    // console.log('Selected items:', this.selectedItems);
    if (this.target !== '') {
      // console.log('Selected items:', item.value.map(a => a[this.target]));
    } else {
      // console.log('Selected items:', item.value);
    }
    this.selection.emit(item.value);
    // console.log('Selected item:', selectedItem);
    // console.log('Selected item:', item);
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredItems.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.itemCtrl.patchValue(val);
          this.selection.emit(val);
        } else {
          this.itemCtrl.patchValue([]);
        }
      });
  }

  checkArray() {

    setTimeout(() => {
      this.filteredItems.next(this.data.slice());
    }, 500);
    // this.filteredItems.next(this.data.slice());
    // console.log('Data check:', this.data);
    // console.log('filteredItems check:', this.filteredItems._events[0]);
  }
}

export interface Item {
  id: string;
  name: string;
  code?: string;
}
