<!-- Navbar -->
<!--<app-navigation-bar></app-navigation-bar>-->
<!--<router-outlet></router-outlet>-->
<div class="col-12 p-2">
  <router-outlet *ngIf="auth.isAuthenticated()">
    <app-menu></app-menu>
<!--    <app-navigation-bar class="mt-3"></app-navigation-bar>-->
<!--    <app-navbar></app-navbar>-->
  </router-outlet>
</div>
<div (document:keydown.shift.s)="doSomething()"></div>
<div class="container" *ngIf="!auth.isAuthenticated()">
  <mat-card class="log-in-card">
    <img mat-card-image src="../assets/tin-can-logo.png" alt="TinCan Logo">
    <mat-card-header class="welcome-header">
      <mat-card-title class="text-center">Welcome to TinCan</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>You need to log in to continue</p>
      <button mat-raised-button color="primary" (click)="auth.login()">Log in now</button>
      <!--      <button mat-raised-button color="primary" (click)="auth.loginAdmin()">Log in now</button>-->
    </mat-card-content>
  </mat-card>
</div>
<app-footer></app-footer>
<ng-template #template let-data>
  <button mat-flat-button color="primary" (click)="copyAccessToken()">Copy Access Token</button>
  <button mat-flat-button color="primary" (click)="copyIdToken()">Copy ID Token</button>
  <pre hidden>
  <code id="accessToken">
    {{data["access_token"]}}
  </code>
  </pre>
</ng-template>
