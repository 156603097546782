import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-rest-filtering-input',
  templateUrl: './rest-filtering-input.component.html',
  styleUrls: ['./rest-filtering-input.component.css']
})

export class RestFilteringInputComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
