import {NgModule} from '@angular/core';
// const default_permissions = require('../../core/utils/user-permissions/defaultPermissions');
// const deepmerge = require('deepmerge');
import * as deepmerge from 'deepmerge';
import * as defaultPerms from '../../core/utils/user-permissions/defaultPermissions';
import {UserServiceV2} from '../services/salestrackServiceV2/crud-endpoints/users.service';
import {UserPermissionsServiceV2} from '../services/salestrackServiceV2/crud-endpoints/user_permissions.service';

@NgModule({
    providers: [UserServiceV2, UserPermissionsServiceV2]
})
export class UserPermissionController {
    constructor(
        private userServiceV2: UserServiceV2,
        private userPermissionsServiceV2: UserPermissionsServiceV2,
    ) {
    }
    async getUsers(user_ids, org_id, usernames) {
        let optionalParams = 'select=id,name,email';
        optionalParams += ',user_permissions(permissions,id)';
        if (user_ids !== null) {
            optionalParams += `&id=in.(${user_ids})`;
        }
        if (org_id !== null) {
            optionalParams += `&owner=eq.${org_id}`;
        }
        if (usernames !== null) {
            optionalParams += `&name=in.(${usernames})`;
        }

        const response = await this.userServiceV2.get(optionalParams).toPromise();
        if (response.ok) {
            return this.appendDefaultPermissions(response.body);
        }
        return null;
    }

    appendDefaultPermissions(users) {
        const returnUsers = [];
        users.forEach(user => {
            const returnUser = {
                'id': user.id,
                'name': user.name,
                'email': user.email,
                'permission_id': user.user_permissions.length === 0 ? undefined : user.user_permissions[0].id,
                'user_permissions': this.assignPermissions(user.user_permissions)
            };
            returnUsers.push(returnUser);
        });
        return returnUsers;
    }

    assignPermissions(user_permissions) {
        if (user_permissions.length === 0) {
            return defaultPerms;
        } else {
            return deepmerge(defaultPerms, user_permissions[0].permissions);
        }
    }

    async createUserPermission(user_id, permissions) {
        const new_user_permissions = {
           user_id,
           permissions
        };
        const result = await this.userPermissionsServiceV2.create(new_user_permissions).toPromise();
        if (result.ok) {
            return true;
       }
        return result;
    }

    async updateUserPermissions(user_id, permissions) {
        const updated_user_permissions = {
            permissions
        };
        const result = await this.userPermissionsServiceV2.update(user_id, updated_user_permissions).toPromise();
        if (result.ok) {
            return true;
       }
        return result;
    }

}
