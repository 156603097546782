import { Injectable } from '@angular/core';
import {AuthService} from './auth/auth.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GetApiReply} from './models/get-api-reply.model';
import {ConfigService} from './services/config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private authS: AuthService,
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  getDataBySearch(
    targetTable: string,
    valueToTarget: string,
    sortBy: string,
    columns: string,
    value: string,
    checkIsActive: boolean,
    specialCondition?: string
  ): Observable<GetApiReply> {
    let include = ``;
    let requestValue = '';
    let isActive = '';
    if (specialCondition.length > 0) {
      specialCondition = `&${specialCondition}`;
    }
    if (value.length > 0) {
      requestValue = `${valueToTarget}=ilike.*${value}*`;
    }
    if (checkIsActive) {
      isActive = '&is_active=eq.true';
    }
    if (columns !== '') {
      include = `&select=${columns}`;
    }
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress() + targetTable}?${requestValue}${include}&order=${sortBy}${specialCondition}${isActive}` +
    `&limit=10&offset=0`,
      this.conf.getHttpOptions()
    );
  }
}
