import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SalestrackApiConfService {

    constructor() {
    }

    getServerAddress() {
      // return 'https://hh-api-mst.srv.handheld.ie/hhdb_api/';
      // return this.postgrestApiService.getServerAddress();
      return 'https://hh-api-dev.srv.handheld.ie/hhdb_api/';
    }
}
