import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthRoutingModule} from './auth-routing.module';
import {CallbackComponent} from './callback/callback.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule
    ],
  declarations: [
    CallbackComponent
  ],
  exports: [
    AuthRoutingModule
  ]
})
export class AuthModule {
}
