<!-- Old Version --

<button
        class="btn btn-clear col-12"

        bsDaterangepicker
        [bsConfig]="bsConfig"
        (bsValueChange)="setDate($event)"
        placement={{placement}}
>
    {{selectedDate[0] | date}} - {{selectedDate[1] | date}}
    <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
</button>

<! -- New Version -->
<input type="text"
       placeholder="Select Date"
       class="form-control"
       bsDaterangepicker
       [bsConfig]="bsConfig"
       (bsValueChange)="setDate($event)"
       placement="left">
