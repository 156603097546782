<div class="col" style="max-height: 70vh; overflow: auto">
  <h2>Delete {{heading}}</h2>
  <div class="row" *ngFor="let item of list; let i = index">
    <div class="col">
      <h4>Delete {{item[valuesToAccess]}}</h4>
      <button class="btn btn-success col-12" (click)="removeFromList(i)">Remove from delete list</button>
      <br>
    </div>
  </div>
  <br>
  <hr>
  <div class="row">
    <div class="col">
      <button class="btn btn-danger col-12" (click)="openModal(template)">Confirm Delete {{list.length}}
        items
      </button>
    </div>
  </div>
  <br><br>
  <ng-template #template>
    <div class="modal-body text-center">
      <p>Are you Sure?</p>
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-primary col-12" (click)="decline()">No Cancel</button>
        </div>
        <div class="col">
          <button type="button" class="btn btn-danger col-12" (click)="confirm()">Yes Delete</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
