import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivationButtonsComponent} from './utils/activation-buttons/activation-buttons.component';
import {DateSelectionComponent} from './utils/date-selection/date-selection.component';
import {DateRangeSelectionComponent} from './utils/date-range-selection/date-range-selection.component';
import {RestFilteringInputComponent} from './utils/_removal/rest-filtering-input/rest-filtering-input.component';
import {ViewAllElementsComponent} from './utils/_removal/view-all-elements/view-all-elements.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {RouterModule} from '@angular/router';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {AuthService} from './auth/auth.service';
import {EditFormComponent} from './utils/_removal/edit-form/edit-form.component';
import {CreateFormComponent} from './utils/create-form/create-form.component';
import {MultipleSelectionTableComponent} from './utils/_removal/multiple-selection-table/multiple-selection-table.component';
import {ErrorMessagesComponent} from './utils/_removal/error-messages/error-messages.component';
import {DeleteListComponent} from './utils/delete-list/delete-list.component';
import {BestBeforeDateRangeSelectionComponent} from './utils/best-before-date-range-selection/best-before-date-range-selection.component';
import {SingleSelectionTableComponent} from './utils/_removal/single-selection-table/single-selection-table.component';
import {DisplayTableWithRemovableItemComponent} from '../_deletions/display-table-with-removable-item/display-table-with-removable-item.component';
import {FooterComponent} from './parts/footer/footer.component';
import {MaterialDateRangeSelectionComponent} from './utils/material-date-range-selection/material-date-range-selection.component';
import {MatDateRangeSelectionComponent} from './utils/mat-date-range-selection/mat-date-range-selection.component';
import {BarcodeListComponent} from './utils/barcode-list/barcode-list.component';
import {BarcodeViewerComponent} from './utils/barcode-viewer/barcode-viewer.component';
import {MaterialRestFilteringInputComponent} from '../_deletions/material-rest-filtering-input/material-rest-filtering-input.component';
import {TooltipComponent} from './utils/tooltip/tooltip.component';
import {PageTitleComponent} from './parts/page-title/page-title.component';
import {MatDialogComponent} from './utils/mat-dialog/mat-dialog.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {AlertsComponent} from './utils/alerts/alerts.component';
import {MaterialDropdownComponent} from './parts/material-dropdown/material-dropdown.component';
import {DialogComponent} from './utils/dialog/dialog.component';
import { NavbarComponent } from './parts/navbar/navbar.component';
import {MenuItemComponent} from './parts/navbar/menu-item/menu-item.component';
import { MaterialAutocompleteComponent } from './parts/material-autocomplete/material-autocomplete.component';
import { DialogDeleteComponent } from './parts/dialog-delete/dialog-delete.component';
import { ConfirmationDialogComponent } from './parts/confirmation-dialog/confirmation-dialog.component';
import { MultiSelectDropdownComponent } from './parts/multi-select-dropdown/multi-select-dropdown.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { MaterialDatePickerComponent } from './parts/material-date-picker/material-date-picker.component';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import {NgxBarcode6Module} from 'ngx-barcode6';

@NgModule({
  declarations: [
    // TODO: Alphabetically sort declarations
    // NavigationBarComponent,
    DialogComponent,
    AlertsComponent,
    ActivationButtonsComponent,
    BestBeforeDateRangeSelectionComponent,
    BarcodeListComponent,
    BarcodeViewerComponent,
    CreateFormComponent,
    DeleteListComponent,
    DisplayTableWithRemovableItemComponent,
    MaterialRestFilteringInputComponent,
    DateSelectionComponent,
    DateRangeSelectionComponent,
    EditFormComponent,
    ErrorMessagesComponent,
    FooterComponent,
    MultipleSelectionTableComponent,
    RestFilteringInputComponent,
    SingleSelectionTableComponent,
    ViewAllElementsComponent,
    MaterialDateRangeSelectionComponent,
    MatDateRangeSelectionComponent,
    MaterialRestFilteringInputComponent,
    TooltipComponent,
    PageTitleComponent,
    MatDialogComponent,
    MaterialDropdownComponent,
    NavbarComponent,
    MenuItemComponent,
    MaterialAutocompleteComponent,
    DialogDeleteComponent,
    ConfirmationDialogComponent,
    MultiSelectDropdownComponent,
    MaterialDatePickerComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    // TODO: Add NgxBarcodeModule
    // NgxBarcodeModule,
    PaginationModule,
    NgMultiSelectDropDownModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    AlertModule,
    MatCardModule,
    MatToolbarModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    // NgxBarcode6Module
  ],
  providers: [AuthService],

  exports: [
    // NavigationBarComponent,
    ActivationButtonsComponent,
    BestBeforeDateRangeSelectionComponent,
    CreateFormComponent,
    DateSelectionComponent,
    DateRangeSelectionComponent,
    DeleteListComponent,
    EditFormComponent,
    ErrorMessagesComponent,
    MultipleSelectionTableComponent,
    RestFilteringInputComponent,
    SingleSelectionTableComponent,
    DisplayTableWithRemovableItemComponent,
    FooterComponent,
    MaterialDateRangeSelectionComponent,
    MatDateRangeSelectionComponent,
    BarcodeListComponent,
    MaterialRestFilteringInputComponent,
    TooltipComponent,
    PageTitleComponent,
    AlertsComponent,
    MaterialDropdownComponent,
    NavbarComponent,
    MaterialAutocompleteComponent,
    MultiSelectDropdownComponent,
    MaterialDatePickerComponent
  ]
})
export class CoreModule {
}
