<div class="row">
  <h6>{{heading}}</h6>
</div>
<div class="row">
  <div (mouseenter)="mouseEnter('div a')" (mouseleave)="mouseLeave('div A')">
  <input class="form-control" type="text" (keyup)="filter()" (click)="showList = true" [(ngModel)]="userInput">
  <div *ngIf="showList">
    <div class="col" id="theOptions" [ngStyle]="{'position': showList? 'absolute': '', 'z-index': showList? '1000': '' }">
    <div class="row" *ngFor="let item of filterList" (click)="userSelected(item)">
      <div class="col" id="item">
        <p>{{item[valueToAccess]}}</p>
      </div>
    </div>
  </div>
  </div>
</div>
<br>
</div>
<div class="row">
  <div class="col">
    <div class="tableBox">
      <div class="row">
      <!-- Change Here! -->
      <!-- Create table headers as need -->
      <div class="col" *ngFor="let item of tableItems" (click)="sortTableFromIt(item.valuesToAccess)">
          <h5>{{item.heading}}</h5>
      </div>
    </div>
    <div class="tableBody">
      <div class="row" *ngFor="let item of tableList; let i = index;">
      <div class="col" *ngFor="let tableItem of tableItems">
        <div *ngIf="tableItem.multipleObject; else oneLayer">
          <div *ngIf="!tableItem.hasPipe; else itemHasPipeMulti"><p>{{item[tableItem.valuesToAccess][tableItem.multipleObjectValue] }}</p></div>
          <ng-template #itemHasPipeMulti>
            <div [ngSwitch]="tableItem.pipeName">
              <p *ngSwitchCase="'date'">
                {{item[tableItem.valuesToAccess][tableItem.multipleObjectValue] | date}}
              </p>
              <p *ngSwitchCase="'number'">
                {{item[tableItem.valuesToAccess][tableItem.multipleObjectValue] | number}}
              </p>
              <p *ngSwitchCase="'percent'">
                {{item[tableItem.valuesToAccess][tableItem.multipleObjectValue] | percent}}
              </p>
              <p *ngSwitchDefault>
                {{item[tableItem.valuesToAccess][tableItem.multipleObjectValue]}}
              </p>
            </div>
                  </ng-template>
                </div>
                <ng-template #oneLayer>
                  <div *ngIf="!tableItem.hasPipe; else itemHasPipe"><p>{{item[tableItem.valuesToAccess]}}</p></div>
                  <ng-template #itemHasPipe>
                    <div [ngSwitch]="tableItem.pipeName">
                    <p *ngSwitchCase="'date'">
                      {{item[tableItem.valuesToAccess] | date}}
                    </p>
                    <p *ngSwitchCase="'number'">
                      {{item[tableItem.valuesToAccess] | number}}
                    </p>
                    <p *ngSwitchCase="'percent'">
                      {{item[tableItem.valuesToAccess] | percent}}
                    </p>
                    <p *ngSwitchDefault>
                      {{item[tableItem.valuesToAccess]}}
                    </p>
                  </div>
                  </ng-template>
                </ng-template>
      </div>
      <div class="col">
        <button class="btn btn-danger col-12" (click)="removeItem(i)">X</button>
      </div>
        </div>
      </div>
    </div>
    </div>
  </div>
<div class='row' *ngIf='actionButton'>
  <div class="col">
    <button class="btn btn-clear col-12" (click)="confirm()" [ngStyle]="{'color': buttonTextColor, 'background-color': buttonBackgroundColor}">{{actionButton}}</button>
  </div>
</div>
