import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-material-date-picker',
  templateUrl: './material-date-picker.component.html',
  styleUrls: ['./material-date-picker.component.css']
})
export class MaterialDatePickerComponent implements OnInit {
  @Input() label = '';
  date = new FormControl(moment());
  // Preset date
  @Input() selectedDate = '';
  // Date that gets sent back to the UI
  @Output() newDate = new EventEmitter<string>();
  @Input() isDisabled: boolean;

  constructor(
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {
    this.date.patchValue(this.selectedDate);
  }

  addEvent(change: string, event: MatDatepickerInputEvent<unknown>) {
    this.logger.info('Selected date (ISO):', moment.utc(event.value).toISOString());
    this.logger.info('Selected date:', moment.utc(event.value).format('Do MMMM, YYYY'));
    this.newDate.emit(moment.utc(event.value).toISOString());
  }

}
