<mat-toolbar class="menu-bar mat-elevation-z1">
  <img src="https://i.imgur.com/57yW7So.png" height="45px" alt="Tin Can Logo">
  <span *ngFor="let item of navItems">
  <!-- Handle branch node buttons here -->
    <span *ngIf="item.children && item.children.length > 0">
    <button #button mat-button [matMenuTriggerFor]="menu.childMenu" #levelOneTrigger="matMenuTrigger" [disabled]="item.disabled" [ngClass]="item.style"
            (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"
            *ngIf="item.perms">
    <mat-icon>{{item.iconName}}</mat-icon>
    <span>{{item.displayName}}</span>
    <mat-icon>expand_more</mat-icon>
    </button>
    <app-menu-item #menu [items]="item.children"></app-menu-item>
    </span>
    <!-- Leaf node buttons here -->
    <span *ngIf="!item.children || item.children.length === 0">
    <button mat-button color="primary" [routerLink]="item.route">
    {{item.displayName}}
    </button>
    </span>
  </span>
  <span class="ms-auto" *ngIf="auth.isAuthenticated()">
    <img *ngIf="logo != ''" [src]="logo" alt="Logo" height="25px">
    <button mat-button [matMenuTriggerFor]="menu">{{name}}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>mail_outline</mat-icon>
        <span>{{email}}</span>
      </button>
      <button mat-menu-item (click)="auth.logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>Log Out</span>
      </button>
    </mat-menu>
  </span>
  <span class="ms-auto" *ngIf="!auth.isAuthenticated()">
      <button mat-menu-item (click)="auth.login()">
        <mat-icon>power_settings_new</mat-icon>
        <span>Log in</span>
      </button>
  </span>
</mat-toolbar>
