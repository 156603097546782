<div class='col' *ngIf="barcodeList.length !== 0">
  <div class="row">
    <div class="col">
      <button mat-flat-button color="primary" class="col-12" (click)="printList()">Print</button>
    </div>
  </div>
  <div class="row" #barcodeToPrints>
    <div class="col-12" *ngFor="let item of barcodeList; let i = index">
      <div class="row">
        <div class="center-">
          <h2 style="text-align: center">{{item.name}}</h2>
          <h4 style="text-align: center">{{item.barcode}}</h4>
          <br>
          <div class="center">
<!--            <ngx-barcode6 [bc-value]="item.barcode" [bc-display-value]="true"></ngx-barcode6>-->
          </div>
          <br>
          <div class="col">
            <button class="btn btn-danger col-12" *ngIf="!printing" (click)="remove(i)">Remove</button>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>
