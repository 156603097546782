import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {CallbackComponent} from './core/auth/callback/callback.component';
import {LoginAlertComponent} from './login-alert/login-alert.component';

const appRoutes: Routes = [
  {path: 'dashboard', component: DashboardComponent},
  {path: 'testing', loadChildren: () => import('src/app/testing/testing.module').then(m => m.TestingModule)},
  {path: 'callback', component: CallbackComponent},
  // {path: '', redirectTo: '/transactions/dockets', pathMatch: 'full'},
  {path: 'transactions', loadChildren: () => import('src/app/transactions/transactions.module').then(m => m.TransactionsModule)},
  {path: 'finance', loadChildren: () => import('src/app/finance/finance.module').then(m => m.FinanceModule)},
  {path: 'inventory', loadChildren: () => import('src/app/inventory/inventory.module').then(m => m.InventoryModule)},
  {path: 'production', loadChildren: () => import('src/app/production/production.module').then(m => m.ProductionModule)},
  {path: 'orders', loadChildren: () => import('src/app/orders/orders.module').then(m => m.OrdersModule)},
  {path: 'services', loadChildren: () => import('src/app/services/services.module').then(m => m.ServicesModule)},
  {path: 'logistics', loadChildren: () => import('src/app/logistics/logistics.module').then(m => m.LogisticsModule)},
  {path: 'admin', loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)},
  {path: 'products', loadChildren: () => import('src/app/products/products.module').then(m => m.ProductsModule)},
  {path: 'customers', loadChildren: () => import('src/app/customers/customers.module').then(m => m.CustomersModule)},
  {path: 'reports', loadChildren: () => import('src/app/reports/reports.module').then(m => m.ReportsModule)},
  {path: 'reports-old', loadChildren: () => import('src/app/reports-old/reports.module').then(m => m.ReportsModule)},
  {path: 'interaction', loadChildren: () => import('src/app/interaction/interaction.module').then(m => m.InteractionModule)},
  {path: 'templates', loadChildren: () => import('src/app/templates/templates.module').then(m => m.TemplatesModule)},
  // {path: 'callback', component: CallbackComponent},
  // {path: '', redirectTo: 'loginalert', pathMatch: 'full'},
  // {path: '', component: PageNotFoundComponent},  // Wildcard route for a 404 page
  // {path: 'loginalert', component: LoginAlertComponent},
  {path: 'login', component: LoginAlertComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ]
})
export class AppRoutingModule {
}
