import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-best-before-date-range-selection',
  templateUrl: './best-before-date-range-selection.component.html',
  styleUrls: ['./best-before-date-range-selection.component.css']
})
export class BestBeforeDateRangeSelectionComponent implements OnInit {
  bsConfig: Partial<BsDaterangepickerConfig> = Object.assign({}, { dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red'});
  filterList;
  lastFilter = 0;
  userInput = '';
  showList = false;
  loading = false;
  checkForIsActive = false;
  filterCount = 0;
  // newBestBeforeDate = null;

  now = moment();
  startOfDay = this.now.startOf('day');
  endOfDay = this.now.endOf('day');

  startOfDayAsDate: String = moment(this.startOfDay.format()).toISOString();
  endOfDayAsDate: String = moment(this.endOfDay.format()).toISOString();

  selectedDate = [
    this.startOfDayAsDate,
    this.endOfDayAsDate
  ];
  @Input() placement = 'top';
  @Output() newBestBeforeDate = new EventEmitter<String[]>();
  @Input() set _preSetDate (preSetDate) {
    const startDate: String = moment(preSetDate[0]).startOf('day').toISOString();
    const endDate: String = moment(preSetDate[1]).endOf('day').toISOString();
    this.selectedDate = [startDate, endDate];
  }

  @Input() set _checkForActiveState(isActive: boolean) {
    this.checkForIsActive = isActive;
  }

  @Input() afterLoadFilter = null;
  @Input() emptyInputsAllowed = true;

  @Input()
  set preSelected(value: string) {
    if (value !== this.userInput) {
      this.lastFilter = 0;
      this.userInput = value;
      // this.filter();
    }
  }

  filterRequest;
  @Input() emitNull = false;
  @Output() chosenItem = new EventEmitter<any>();
  specialCondition = '';
  optionsList = [];
  bsModalRef: BsModalRef;

  @Input() set specialConditions(specialCondition: string) {
    this.specialCondition = specialCondition;
    // this.filter();
  }

  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    if (this.emptyInputsAllowed) {
      // this.filter();
    }
  }

  setDate(date) {
    // this.logger.log( 'From date-range-selection setDate: ' + date[0].toISOString());
    // this.logger.log( 'From date-range-selection setDate: ' + date[1].toISOString());

    const startOfFirstDay: String = moment(date[0]).startOf('day').toISOString();
    const endOfLastDay: String = moment(date[1]).endOf('day').toISOString();
    const startDate: String = moment(date[0]).startOf('day').format('DD-MM-YYYY');
    const endDate: String = moment(date[1]).endOf('day').format('DD-MM-YYYY');

    this.logger.log( 'From date-range-selection setDate: ' + startOfFirstDay);
    this.logger.log( 'From date-range-selection setDate: ' + endOfLastDay);
    this.logger.log( 'startDate: ' + startDate);
    this.logger.log( 'endDate: ' + endDate);

    this.selectedDate = [startOfFirstDay, endOfLastDay];
    this.logger.log( this.selectedDate);
    this.newBestBeforeDate.emit(
      [
        startDate,
        endDate
      ]
    );
  }
}
