<h2 mat-dialog-title class="mat-dialog-title">Scan Successful</h2>
<mat-dialog-content>

  <div class="mat-dialog-content-body">
    <!-- <iframe src="{{ data.qrcode.data }}"></iframe> -->
    <!--    <p>{{ data.qrcode | json }}</p>-->
    <!--    <p>{{ data.qrcode.data }}</p>-->
  </div>
  <p>Scan the next voucher</p>

</mat-dialog-content>

<mat-dialog-actions>
  <!--  <button mat-button mat-dialog-close>Cancel</button>-->
  <button mat-button [mat-dialog-close]="true">OKay</button>
</mat-dialog-actions>
