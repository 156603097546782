import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrls: ['./date-range-selection.component.css']
})
export class DateRangeSelectionComponent implements OnInit {
  bsConfig: Partial<BsDaterangepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});
  faCalendar = faCalendar;
  now = moment();
  startOfDay = moment().startOf('day');
  endOfDay = moment().endOf('day');

  startOfDayAsDate: string = moment(this.startOfDay.format()).toISOString();
  endOfDayAsDate: string = moment(this.endOfDay.format()).toISOString();

  selectedDate = [
    this.startOfDayAsDate,
    this.endOfDayAsDate
  ];
  @Input() placement = 'bottom';
  @Output() newDate = new EventEmitter<string[]>();

  @Input() set _preSetDate(preSetDate) {
    this.logger.info('selectedDate: ', this.selectedDate);
    if (typeof preSetDate === 'string') {
      preSetDate = preSetDate.split(',');
    }
    this.logger.info('preSetDate: ', preSetDate);
    const startDate: string = moment(preSetDate[0]).startOf('day').toISOString();
    const endDate: string = moment(preSetDate[1]).endOf('day').toISOString();
    this.selectedDate = [startDate, endDate];
    this.logger.info('selectedDate: ', this.selectedDate);
  }

  bsRangeValue: Date[];
  @Input() set preSetDate(date) {
    // this.logger.debug('preSetDate: ', date);
    if (typeof date === 'string') {
      date = date.split(',');
    }
    const startDate = new Date(date[0]);
    const endDate = new Date(date[1]);
    this.bsRangeValue = [startDate, endDate];
  }

  constructor(
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {
  }

  setDate(date) {
    // this.logger.log( 'From date-range-selection setDate: ' + date[0].toISOString());
    // this.logger.log( 'From date-range-selection setDate: ' + date[1].toISOString());

    const startOfFirstDay: string = moment(date[0]).startOf('day').toISOString();
    const endOfLastDay: string = moment(date[1]).endOf('day').toISOString();

    // this.logger.log('From date-range-selection setDate: ' + startOfFirstDay);
    // this.logger.log('From date-range-selection setDate: ' + endOfLastDay);

    this.selectedDate = [startOfFirstDay, endOfLastDay];
    this.logger.info('selectedDate:', this.selectedDate);
    this.newDate.emit(
      [
        startOfFirstDay,
        endOfLastDay
      ]
    );
  }

}
