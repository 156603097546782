<div (mouseenter)="mouseEnter('div a') " (mouseleave)="mouseLeave('div a')">
  <mat-form-field appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput (keyup)="filter()" (keydown.backspace)="backSpace()" (click)="showList = true"
           [(ngModel)]="userInput">
    <button *ngIf="userInput" matSuffix mat-icon-button aria-label="Clear" (click)="userInput=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div *ngIf="showList">
    <div class="col" id="theOptions"
         [ngStyle]="{'position': showList? 'absolute': '', 'z-index': showList? '1000': '' }">
      <mat-option value="audi" *ngIf="loading">
        <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      </mat-option>
      <mat-option *ngFor="let item of filterList" [value]="item.value" (click)="userSelected(item)">
        {{item[valueToTarget]}}
      </mat-option>
    </div>
  </div>
</div>
