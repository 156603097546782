import { Component, OnInit } from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  selectedIndex = 0;

  tabs = [
    {id: 0, name: 'Products'},
    {id: 1, name: 'Product Categories'},
    {id: 2, name: 'Customers'},
    {id: 3, name: 'Customer Types'}
  ];

  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit() {
  }

  nextStep() {
    if (this.selectedIndex !== this.tabs.length) {
      this.selectedIndex = this.selectedIndex + 1;
    }
    this.logger.log(this.selectedIndex);
  }

  previousStep() {
    if (this.selectedIndex !== 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
    this.logger.log(this.selectedIndex);
  }

}
