<div class="container">
  <div class="row">
    <div class="col-12 mt-lg-5">
      <div class="loading">
<!--        <img src="../assets/loading.svg" alt="loading">-->
<!--        <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">-->
<!--&lt;!&ndash;          <span class="sr-only">Loading...</span>&ndash;&gt;-->
<!--        </div>-->
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
