<div class="col-12" *ngIf="condition">
  <div class="row page-title">
    <div class="col-md-9">
      <h3>
        <img *ngIf="logoB64" [src]="logoB64" alt="Logo">
        <img *ngIf="logo" [src]="logo" height="35rem" alt="Logo">
        <mat-icon *ngIf="icon != ''" aria-hidden="false" aria-label="Example home icon">{{icon}}</mat-icon>
        {{title}}
      </h3>
    </div>
    <div class="col-md-3">
      <button mat-raised-button color="{{color}}" class="float-end"
              *ngIf="btnCondition" (click)="changeBooleanValue()">{{btnText}}</button>
    </div>
  </div>
</div>
