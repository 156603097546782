import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {
  @Output() button: EventEmitter<any> = new EventEmitter();
  @Output() buttonStateChange = new EventEmitter<boolean>();
  @Output() buttonAction = new EventEmitter<boolean>();
  @Input() title = 'Page Title';
  @Input() icon = '';
  @Input() logo = null;
  @Input() logoB64 = null;
  @Input() color = '';
  @Input() btnText = '';
  @Input() condition = true;
  @Input() btnCondition: boolean;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.color === '') {
      this.color = 'primary';
    }
    if (this.logoB64) {
      this.logoB64 = 'data:image/png;base64,' + this.logoB64;
      this.logoB64 = this.sanitizer.bypassSecurityTrustUrl(this.logoB64);
      // this.logger.log('Logo: ' + this.companyLogo);
    } else {
      // console.log('No logo:', this.logoB64);
    }
  }

  changeBooleanValue() {
    this.btnCondition = !this.btnCondition;
    this.buttonStateChange.emit(this.btnCondition);
    this.buttonAction.emit(this.btnCondition);
  }

}
