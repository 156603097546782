<!--<mat-card>-->
<!--  <mat-card-content>-->
    <mat-form-field appearance="outline" [class.full-width]="fullWidth">
      <mat-label *ngIf="items.length != 0">{{label}}</mat-label>
      <mat-label *ngIf="items.length == 0">
        <div class="select-placeholder-container">
          <span>Loading {{label}}s...
          <mat-spinner class="spinner" diameter="20"></mat-spinner></span>
        </div>
      </mat-label>
<!--      <mat-select [(ngModel)]="selectedItem" (selectionChange)="itemSelected(selectedItem)" disableOptionCentering>-->
<!--        <mat-option *ngFor="let item of items" [value]="item">-->
<!--          <span *ngIf="target == ''">{{item}}</span>-->
<!--          <span *ngIf="target != ''">{{item[target]}}</span>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
      <mat-select [(ngModel)]="selectedItem" (selectionChange)="itemSelected($event)">
        <mat-option *ngFor="let item of items" [value]="item">
          <span *ngIf="target == ''">{{item}}</span>
          <span *ngIf="target != ''">{{item[target]}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

<!--  </mat-card-content>-->
<!--</mat-card>-->
