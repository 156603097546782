import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {NGXLogger} from 'ngx-logger';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css']
})
/*
@author christopher buggy
to use this component bind newDate to a variable in the outer component
it will produce a string value removing the time from it.
*/
export class DateSelectionComponent implements OnInit {
  faCalendar = faCalendar;
  dateFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS';

  constructor(
    private logger: NGXLogger
  ) {
  }
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});

  /***Without Moment  ***/
  selectedDate = new Date(new Date(Date.now()).toISOString().split('T')[0]).toISOString();

  @Input() placement = 'bottom';
  @Input() format = 'mediumDate';
  @Input() moment = false;
  @Input() momentOutputFormat = 'YYYY-MM-DD';
  @Output() newDate = new EventEmitter<string>();
  @Output() buttonStateChange = new EventEmitter<boolean>();
  @Input() disabled: boolean;
  now = moment();

  @Input() _preSetDate(preSetDate) {
    this.selectedDate = new Date(new Date(preSetDate).toISOString().split('T')[0]).toISOString();
  }
  @Input() set preSetDate(date) {
    if (date != null) {
      this.selectedDate = moment(date).format(this.dateFormat);
    } else {
      this.selectedDate = null;
    }
  }

  ngOnInit() {
    this.logger.log('Date', this.selectedDate);
  }

  /***Without Moment  ***/
  setDate(date) {
    const momentDate = moment(date).startOf('day').format(this.momentOutputFormat);
    const prettyDate = moment(date).startOf('day').format('Do MMM, YYYY');
    this.selectedDate = momentDate;
    this.newDate.emit(momentDate);
    this.logger.debug('Selected date:', prettyDate);
  }

}
