<button
  mat-raised-button color="primary" class="form-control col-12"
  bsDaterangepicker
  placement="bottom"
  [bsConfig]="bsConfig"
  [bsValue]="bsRangeValue"
  (bsValueChange)="setDate($event)">
  {{selectedDate[0] | date}} - {{selectedDate[1] | date}}
  <mat-icon>today</mat-icon>
</button>
