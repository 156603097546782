import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';
import {GetApiReply} from '../models/get-api-reply.model';
import {tap} from 'rxjs/operators';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  /** Company details */

  getOrganisations(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}organisations?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getMyOrgId() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_organisation`,
      this.conf.getHttpOptions()
    );
  }

  organisationId() {
    return this.conf.getOwnerId();
  }

  updateOrganisation(item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}organisations?id=eq.${id}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateOrganisations(optionalParams = '', item) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}organisations?${optionalParams}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  /** Handhelds */

  getHandhelds(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}handhelds?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createHandheld(handheld) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds`,
      handheld,
      this.conf.getHttpOptions(),
    );
  }

  updateHandheld(handheld) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds?id=eq.${handheld.id}`,
      handheld,
      this.conf.getHttpOptions()
    );
  }

  deleteHandheld(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds?id=eq.${id}`,
      this.conf.getHttpOptions(),
    );
  }

  getHandheldToRoute(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}route_links?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createHandheldToRoute(hhToRoute) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}route_links`,
      hhToRoute,
      this.conf.getHttpOptions(),
    );
  }

  deleteHandheldToRouteLink(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}route_links?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Logo */

  getLogos(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}logos?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getLogo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Accept': 'application/octet-stream'
      }),
      responseType: 'text'
    };
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}logos?select=bytea_value&limit=1`,
      // this.conf.getHttpOptions()
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return res;
      }),
    );
  }

  getLogoAlt(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}logos?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createLogo(logo) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}logos`,
      logo,
      this.conf.getHttpOptions()
    );
  }

  updateLogo(optionalParams, logo) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}logos?${optionalParams}`,
      logo,
      this.conf.getHttpOptions()
    );
  }
}
