import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-single-selection-table',
  templateUrl: './single-selection-table.component.html',
  styleUrls: ['./single-selection-table.component.css']
})
export class SingleSelectionTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
