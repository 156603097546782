import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-view-all-elements',
  templateUrl: './view-all-elements.component.html',
  styleUrls: ['./view-all-elements.component.css']
})

export class ViewAllElementsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
