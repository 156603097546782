<nav class="navbar navbar-expand-xl fixed-top">
  <a class="navbar-brand" [routerLink]="'/'">
    <!--  <a class="navbar-brand" [routerLink]="'/testing'">-->
    <!--        <img src="https://d36rz30b5p7lsd.cloudfront.net/kraftbrands/heinzcouk/beanz/product/images/Classic%20Beanz.png" height="45px">-->
    <img src="https://i.imgur.com/57yW7So.png" height="45px" alt="logo">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse">
    <!--    <ul class="navbar-nav mr-auto" *ngIf="auth.isAuthenticated()">-->
    <ul class="navbar-nav mr-auto mb-2 mb-lg-0" *ngIf="auth.isAuthenticated()">
      <!-- ########### -->
      <!-- Transactions -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleTransactions">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="transactions">
          <i class="fas fa-user"></i> Transactions</a>
      </li>
      <mat-menu #transactions="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [matMenuTriggerFor]="dockets">Dockets</button>
        <button mat-menu-item [matMenuTriggerFor]="invoices">Invoices</button>
        <button mat-menu-item [matMenuTriggerFor]="transactionsOther">Other</button>
      </mat-menu>
      <!-- Dockets -->
      <mat-menu #dockets="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/transactions/dockets'" class="nav-link">Dockets</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/transactions/docket-reprint'" class="nav-link ">Docket Reprint</a>
        </button>
      </mat-menu>
      <!-- Invoices -->
      <mat-menu #invoices="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/transactions/invoice-creation'" class="nav-link">Create Invoices</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/transactions/invoices'" class="nav-link ">View Invoices</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/transactions/invoice-export'" class="nav-link ">Export Invoices</a>
        </button>
      </mat-menu>
      <!-- Other -->
      <mat-menu #transactionsOther="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/transactions/statements'" class="nav-link ">Statements</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/transactions/credit-notes'" class="nav-link ">Credit Notes</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/transactions/commissions-view'" class="nav-link ">Agent Commissions</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Orders -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleOrders">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="orders">
          <i class="fas fa-file-invoice"></i> Orders</a>
      </li>
      <mat-menu #orders="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/orders/standing-orders'" class="nav-link">Standing Orders</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/orders/additional-orders'" class="nav-link ">Additional Orders</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/orders/service-orders'" class="nav-link ">Service Orders</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Production -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleProduction">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="production">
          <i class="fas fa-industry"></i> Production</a>
      </li>
      <mat-menu #production="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/production/manage-production-lists'" class="nav-link text-small">Production
            Lists</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/production/generate-production-lists'" class="nav-link text-small">Generate Production
            Lists</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/production/production-groups'" class="nav-link text-small">Production Groups</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/production/input'" class="nav-link text-small">Input</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/production/formulae'" class="nav-link text-small">Formulae</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/production/batch'" class="nav-link text-small">Batch</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Inventory -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleInventory">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="inventory">
          <i class="fas fa-user"></i> Inventory</a>
      </li>
      <mat-menu #inventory="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/inventory/stock-count-alert'" class="nav-link">Stock Count Alert</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/inventory/advanced-stock-search'" class="nav-link ">Advanced Stock Search</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/inventory/stock-location'" class="nav-link ">Stock Location</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Logistics -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleLogistics">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="logistics">
          <i class="fas fa-truck"></i> Logistics</a>
      </li>
      <mat-menu #logistics="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [matMenuTriggerFor]="dispatch">Dispatch</button>
        <button mat-menu-item [matMenuTriggerFor]="deliveries">Deliveries</button>
        <button mat-menu-item [matMenuTriggerFor]="suppliers">Suppliers</button>
      </mat-menu>
      <!-- Dispatch -->
      <mat-menu #dispatch="matMenu" [overlapTrigger]="false">
        <!--
              {path: 'manage-dispatch-list', component: ManageDispatchListComponent},
      {path: 'new-dispatch-list', component: NewDispatchListComponent},
      {path: 'dispatch-group', component: DispatchGroupComponent},
        -->
        <button mat-menu-item>
          <a [routerLink]="'/logistics/dispatch-group'" class="nav-link">Dispatch Groups</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/logistics/new-dispatch-list'" class="nav-link">Generate Dispatch Lists</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/logistics/manage-dispatch-list'" class="nav-link ">Manage Dispatch Lists</a>
        </button>
      </mat-menu>
      <!-- Deliveries -->
      <mat-menu #deliveries="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/logistics/delivery-lists'" class="nav-link ">Delivery Lists</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/logistics/generate-delivery-lists'" class="nav-link ">Generate Delivery Lists</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/logistics/suppliers-list'" class="nav-link ">Suppliers List</a>
        </button>
      </mat-menu>
      <!-- Suppliers -->
      <mat-menu #suppliers="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/logistics/suppliers-list'" class="nav-link text-small pb-0 ">Suppliers List</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Finance -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleFinance">
        <a class="nav-link dropdown-toggle nav-process" [matMenuTriggerFor]="finance">
          <i class="fas fa-coins"></i> Finance</a>
      </li>
      <mat-menu #finance="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [matMenuTriggerFor]="exports">Exports</button>
        <button mat-menu-item [matMenuTriggerFor]="discounts">Discounts</button>
      </mat-menu>
      <!-- Exports -->
      <mat-menu #exports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/finance/account-exports'" class="nav-link">Account Exports</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/edi-exports'" class="nav-link ">EDI Exports</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/csv-exports'" class="nav-link ">Custom Exports</a>
        </button>
      </mat-menu>
      <!-- Discounts -->
      <mat-menu #discounts="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/finance/product-discounts'" class="nav-link ">Product Discounts</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/customer-discounts'" class="nav-link ">Customer Discounts</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/billing-discounts'" class="nav-link ">Billing Discounts</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/product-qty-deal'" class="nav-link ">Product Quantity Deal</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/finance/bulk-discounts'" class="nav-link ">Bulk Discounts</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Customers -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleCustomers">
        <a class="nav-link dropdown-toggle nav-assignment" [matMenuTriggerFor]="customers">
          <i class="fas fa-user"></i> Customers</a>
      </li>
      <mat-menu #customers="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/customers/customers-list'" class="nav-link">Customers</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/customers/customer-specific-prices'" class="nav-link">Customer Specific Prices</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/customers/customer-group-update'" class="nav-link">Customer Group Update</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/customers/customers-on-route'" class="nav-link">Customers on Route</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/customers/customer-specific-price-group-update'" class="nav-link">Customer Specific Price
            Group Update</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Services -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleProducts">
        <a class="nav-link dropdown-toggle nav-assignment" [matMenuTriggerFor]="services">
          <i class="fas fa-user"></i> Services</a>
      </li>
      <mat-menu #services="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/services/services-list'" class="nav-link">Service List</a>
        </button>
        <!--        <button mat-menu-item>-->
        <!--          <a [routerLink]="'/reports/service-reports/generate-service-report'" class="nav-link">Generate Service Report</a>-->
        <!--        </button>-->
        <button mat-menu-item>
          <a [routerLink]="'/reports/service-reports/checklist-template'" class="nav-link">Checklist Template</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Products -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleProducts">
        <a class="nav-link dropdown-toggle nav-assignment" [matMenuTriggerFor]="products">
          <i class="fas fa-user"></i> Products</a>
      </li>
      <mat-menu #products="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/products/products-list'" class="nav-link">Products</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/product-price-list'" class="nav-link ">Price List by Product</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/mass-price-update'" class="nav-link ">Mass Price Update</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/mass-product-update'" class="nav-link ">Mass Product Update</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Admin -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleAdmin">
        <a class="nav-link dropdown-toggle nav-settings" [matMenuTriggerFor]="admin"><i class="fas fa-user-cog"></i>
          Admin</a>
      </li>
      <mat-menu #admin="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [matMenuTriggerFor]="adminOrganisation">Organisation Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminFinancial">Financial Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminCustomer">Customer Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminProducts">Product Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminStorage">Storage Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminOther">Other Settings</button>
      </mat-menu>
      <!-- Organisation Settings -->
      <mat-menu #adminOrganisation="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/organisation-settings/company-details'" class="nav-link">Company Details</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/organisation-settings/account-package-settings'" class="nav-link">Account Package
            Settings</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/organisation-settings/billing-details'" class="nav-link">Billings Details</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/organisation-settings/handheld-settings'" class="nav-link">Handheld Settings</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/organisation-settings/logo-upload'" class="nav-link">Logo Upload</a>
        </button>
      </mat-menu>
      <!-- Financial Settings -->
      <mat-menu #adminFinancial="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/financial-settings/bank-details'" class="nav-link">Bank Details</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/financial-settings/account-details'" class="nav-link">Account Details</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/financial-settings/vat-rates'" class="nav-link ">Vat Rates</a>
        </button>
      </mat-menu>
      <!-- Customer Settings -->
      <mat-menu #adminCustomer="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/customer-settings/depots'" class="nav-link ">Depots</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/customer-settings/customer-types'" class="nav-link ">Customer Types</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/customer-settings/routes'" class="nav-link ">Routes</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/customer-settings/billing-frequency'" class="nav-link ">Billing Frequency</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/customer-settings/inactive-customers'" class="nav-link ">Inactive Customers</a>
        </button>
      </mat-menu>
      <!-- Product Settings -->
      <mat-menu #adminProducts="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/product-settings/product-categories'" class="nav-link ">Product Categories</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/product-settings/sub-product-categories'" class="nav-link ">Sub-Product
            Categories</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/product-settings/quantifiers'" class="nav-link ">Quantifiers</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/product-settings/inactive-products'" class="nav-link ">Inactive Products</a>
        </button>
      </mat-menu>
      <!-- Storage Settings -->
      <mat-menu #adminStorage="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/storage-settings/storage-locations'" class="nav-link ">Storage Locations</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/storage-settings/storage-location-types'" class="nav-link ">Storage Location
            Types</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/storage-settings/storage-areas'" class="nav-link ">Storage Areas</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/storage-settings/storage-area-types'" class="nav-link ">Storage Area Types</a>
        </button>
      </mat-menu>
      <!-- Other Settings -->
      <mat-menu #adminOther="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/admin/other/process'" class="nav-link ">Process</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/other/edi-settings'" class="nav-link ">EDI Settings</a>
        </button>

        <button mat-menu-item>
          <a [routerLink]="'/admin/commissions'" class="nav-link ">Commissions</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Interaction -->
      <!-- ########### -->
      <!--      <li class="nav-item" *ngIf="roleInteraction">-->
      <li class="nav-item">
        <a class="nav-link dropdown-toggle nav-settings" [matMenuTriggerFor]="interaction">
          <i class="fas fa-qrcode"></i> Interaction</a>
      </li>
      <mat-menu #interaction="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/interaction/barcodes'" class="nav-link">Barcodes</a>
        </button>
      </mat-menu>
      <!-- ####### -->
      <!-- Reports -->
      <!-- ####### -->
      <li class="nav-item" *ngIf="roleReports">
        <a class="nav-link dropdown-toggle nav-performance" [matMenuTriggerFor]="reports">
          <i class="fas fa-chart-line"></i> Reports</a>
      </li>
      <!-- Reports Dropdown -->
      <mat-menu #reports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [matMenuTriggerFor]="salesReports">Sales Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="prodReports">Production Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="servicingReports">Servicing Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="stockReports">Stock Reports</button>
        <!--        <button mat-menu-item [matMenuTriggerFor]="serviceReports">Service Reports</button>-->
        <button mat-menu-item [matMenuTriggerFor]="financialReports">Financial Reports</button>
      </mat-menu>
      <!-- Sales Reports -->
      <mat-menu #salesReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports-old/sales-reports/generate-sales-report'" class="nav-link">Generate Sales
            Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/saved-sales-report'" class="nav-link">Saved Sales Reports</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/comparison-sales-report'" class="nav-link">Comparison Sales
            Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/summary-sales-report'" class="nav-link">Summary Sales Report</a>
        </button>
      </mat-menu>
      <!-- Stock Reports -->
      <mat-menu #stockReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports/stock-reports/generate-stock-report'" class="nav-link">Generate Stock Report</a>
        </button>
      </mat-menu>
      <!-- Production Reports -->
      <mat-menu #prodReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports/production-reports/generate-production-report'" class="nav-link">Generate
            Production Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/production-reports/saved-production-report'" class="nav-link">Saved Production
            Reports</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/production-reports/comparison-production-report'" class="nav-link">Comparison
            Production
            Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/production-reports/summary-production-report'" class="nav-link">Summary Production
            Report</a>
        </button>
      </mat-menu>
      <!-- Servicing Reports -->
      <mat-menu #servicingReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports/servicing-reports/generate-servicing-report'" class="nav-link">Generate Servicing
            Report</a>
        </button>
      </mat-menu>
      <!-- Stock Reports -->
      <mat-menu #stockReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports/stock-reports/generate-stock-report'" class="nav-link">Generate Stock Report</a>
        </button>
      </mat-menu>
      <!-- Financial Reports -->
      <mat-menu #financialReports="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/generate-sales-report'" class="nav-link">Generate Financial
            Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/saved-sales-report'" class="nav-link">Saved Financial Reports</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/comparison-sales-report'" class="nav-link">Comparison Financial
            Report</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/reports/sales-reports/summary-sales-report'" class="nav-link">Summary Financial Report</a>
        </button>
      </mat-menu>
      <!-- ########### -->
      <!-- Dashboard -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="roleDashboard">
        <a class="nav-link dropdown-toggle nav-performance" [matMenuTriggerFor]="dashboard">
          <i class="fas fa-chart-pie"></i> Dashboard</a>
      </li>
      <mat-menu #dashboard="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <a [routerLink]="'/dashboard'" class="nav-link ">Sales</a>
        </button>
        <button mat-menu-item>
          Production
        </button>
        <button mat-menu-item>
          Finance
        </button>
      </mat-menu>
    </ul>
    <ul class="navbar-nav" *ngIf="auth.isAuthenticated()">
      <li class="nav-item">
        <a class="nav-link navbar-brand">
          <!--          <img style="height: 25px;" *ngIf="base64textString" src={{base64textString}} alt="" id="organisationLogo">-->
          <img *ngIf="loaded" [src]="base64textString" alt="Logo"/>
          <img [src]="base64textString" alt="Logo"/>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="userDropdown">{{name}}</a>
      </li>
      <mat-menu #userDropdown="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <mat-icon>mail_outline</mat-icon>
          <span>{{email}}</span>
        </button>
        <button mat-menu-item>
          <mat-icon>power_settings_new</mat-icon>
          <span (click)="auth.logout()">Log Out</span>
        </button>
      </mat-menu>
    </ul>
    <ul class="navbar-nav ml-auto" *ngIf="!auth.isAuthenticated()">
      <li class="nav-item">
        <a class="nav-link" (click)="auth.login()">
          <fa-icon [icon]="faSignIn"></fa-icon>
          Log in</a>
      </li>
    </ul>
  </div>
</nav>

