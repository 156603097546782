import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from './core/auth/auth.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {NavigationBarComponent} from './core/navigation-bar/navigation-bar.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginAlertComponent} from './login-alert/login-alert.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {CoreModule} from './core/core.module';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {AuthModule} from './core/auth/auth.module';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {PrettyjsoncPipe} from './prettyjsonc.pipe';
import {PrettyjsonPipe} from './prettyjson.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TestingModule} from './testing/testing.module';
import {UserPermissionsServiceV2} from './core/services/salestrackServiceV2/crud-endpoints/user_permissions.service';
import {UserServiceV2} from './core/services/salestrackServiceV2/crud-endpoints/users.service';
import {UserPermissionController} from './core/controllers/userPermissionsController';
import {LoggerModule, NGXLogger, NGXLoggerHttpService, NgxLoggerLevel, NGXMapperService} from 'ngx-logger';
import {NgxPrintModule} from 'ngx-print';
import {AlertModule} from 'ngx-bootstrap/alert';
import {MatDialogComponent} from './core/utils/mat-dialog/mat-dialog.component';
import {DialogDeleteComponent} from './core/parts/dialog-delete/dialog-delete.component';
import {PaginationConfig, PaginationModule} from 'ngx-bootstrap/pagination';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AppComponent} from './app.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    DashboardComponent,
    LoginAlertComponent,
    PageNotFoundComponent,
    PrettyjsoncPipe,
    PrettyjsonPipe
  ],
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    AuthModule,
    HttpClientModule,
    FontAwesomeModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ProgressbarModule.forRoot(),
    ChartsModule,
    SortableModule.forRoot(),
    BsDropdownModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatRippleModule,
    MatCardModule,
    MatNativeDateModule,
    TestingModule,
    MatInputModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgxPrintModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: 'https://logs.logdna.com/logs/ingest?hostname=:hostname&mac=:mac&ip=:ip&now=:now',
      // level: NgxLoggerLevel.DEBUG,
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: true,
      disableConsoleLogging: false,
      timestampFormat: 'medium'
    }),
    PaginationModule.forRoot(),
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule
  ],
  providers: [
    AuthService,
    {provide: LOCALE_ID, useValue: 'en-ie'},
    NGXLogger,
    NGXMapperService,
    NGXLoggerHttpService,
    ThemeService,
    UserPermissionController,
    UserPermissionsServiceV2,
    UserServiceV2,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    NGXLogger,
    NGXMapperService,
    NGXLoggerHttpService,
    PaginationConfig,
    ThemeService
  ],
  entryComponents: [DialogDeleteComponent, MatDialogComponent],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
