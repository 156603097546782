<div class="col-12" *ngIf="alerts.length != 0">
  <div class="row">
    <div class="col-12">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="dismissible" (onClosed)="onClosed(alert)">
          <strong *ngIf="alert.type == 'danger'">Error!</strong>
          <strong *ngIf="alert.type == 'warning'">Warning!</strong> {{ alert.msg }}</alert>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button mat-raised-button color="primary" *ngIf="alerts.length > 1" (click)="clear()">Dismiss All</button>
    </div>
  </div>
</div>
