import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CommonService} from '../../core/common.service';
import {ViewAllElementsComponent} from '../../core/utils/_removal/view-all-elements/view-all-elements.component';

@Component({
  selector: 'app-material-rest-filtering-input',
  templateUrl: './material-rest-filtering-input.component.html',
  styleUrls: ['./material-rest-filtering-input.component.css']
})
export class MaterialRestFilteringInputComponent implements OnInit {
  filterList;
  lastFilter = 0;
  userSelection = {valueToAccess: '', id: null};
  userInput = '';
  showList = false;
  loading = false;
  checkForIsActive = false;
  filterCount = 0;
  // label = '';
  @Input() valueToAccess: string;
  @Input() label: string;
  @Input() targetTable: string;
  @Input() sortBy: string;
  @Input() valueToTarget: string;
  @Input() columns = '';
  @Input() emptyInputsAllowed = true;
  @Input() autoFill = false;

  @Input() set specialConditions(specialCondition: string) {
    this.specialCondition = specialCondition;
    this.filter();
  }

  @Input() set _checkForActiveState(isActive: boolean) {
    this.checkForIsActive = isActive;
  }

  @Input() afterLoadFilter = null;

  @Input()
  set preSelected(value: string) {
    if (value !== this.userInput) {
      this.lastFilter = 0;
      this.userInput = value;
      this.filter();
    }
  }

  filterRequest;
  @Input() emitNull = false;
  @Output() chosenItem = new EventEmitter<any>();
  specialCondition = '';
  optionsList = [];
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private coreS: CommonService
  ) {
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  ngOnInit() {
    if (this.emptyInputsAllowed) {
      this.filter();
    }
  }

  userSelected(theChosen) {
    this.userInput = String(theChosen[this.valueToTarget]);
    this.userSelection = {...theChosen};
    this.chosenItem.emit({...theChosen});
    this.showList = false;
  }

  openViewAllComponent() {
    const initialState = {
      list: [
        this.valueToAccess,
        this.targetTable,
        this.columns,
        this.sortBy
      ],
      title: 'Modal with component'
    };
    this.bsModalRef = this.modalService.show(ViewAllElementsComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  filter() {
    if ((this.userInput.length > 0 || this.emptyInputsAllowed) && Date.now() > this.lastFilter + 500) {
      this.filterCount = this.filterCount > 10000 ? 1 : this.filterCount + 1;
      const localCount = this.filterCount;
      this.loading = true;
      this.coreS.getDataBySearch(
        this.targetTable,
        this.valueToTarget,
        this.sortBy,
        this.columns,
        this.userInput,
        this.checkForIsActive,
        this.specialCondition
      ).subscribe(res => {
        if (localCount === this.filterCount) {
          if (res.body) {
            if (this.afterLoadFilter === null) {
              this.filterList = res.body;
              this.loading = false;
              // if (res.body.length === 1 && this.autoFill) {
              if (this.autoFill) {
                this.userSelected(res.body[0]);
              }
            } else {
              if (this.afterLoadFilter.conditionState === 'not') {
                this.filterList = res.body.filter(rd => {
                  return rd[this.afterLoadFilter.item] !== this.afterLoadFilter.condition;
                });
                this.loading = false;
                if (res.body.length === 1 && this.autoFill) {
                  this.userSelected(res.body[0]);
                }
              } else {
                this.filterList = res.body.filter(rd => {
                    return rd[this.afterLoadFilter.item] === this.afterLoadFilter.condition;
                  }
                );
                this.loading = false;
                if (res.body.length === 1 && this.autoFill) {
                  this.userSelected(res.body[0]);
                }
              }
            }
          }
        }
      });
      this.lastFilter = Date.now();
    } else if (this.userInput === '') {
      this.chosenItem.emit(null);
    }
  }

  mouseEnter(divA: string) {
    this.showList = true;
  }

  mouseLeave(divA: string) {
    this.showList = false;
  }

  backSpace() {
    this.userInput = '';
    this.filter();
  }

}
