import {Component, HostListener, Inject, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
import {AuthService} from './core/auth/auth.service';
import {AppService} from './app.service';
import {NGXLogger} from 'ngx-logger';
import {take} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    // MatDialogRef,
    // MatDialogData,
    {
      provide: BsDropdownConfig,
      useValue: {isAnimated: true, autoClose: true}
    }
  ]
})
export class AppComponent implements OnInit {
  title = 'TinCan';
  faCoffee = faCoffee;
  quotes: any = [];
  number = 0;

  hotkeys = [];
  @ViewChild('template') customTemplate: TemplateRef<any>;

  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private appS: AppService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    // public dialogRef: MatDialogRef<'template'>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.developerQuotes();
    auth.handleAuthentication();
    // this.cacheS.getCustomers();
    // this.cacheS.getProducts();
  }

  ngOnInit() {
    // this.logger.log('%c Oh my heavens! ', 'background: #222; color: #bada55');
    this.appS.addShortcut({ keys: 'shift.z' }).pipe(
      take(2)
    ).subscribe(console.log);

    // Unsubscribe if you need to
    this.appS.addShortcut({ keys: 'meta.j' }).subscribe();

    // keydown.code.shift.alt.Period
    this.appS.addShortcut({keys: 'shift.alt.q'}).subscribe(() => {
      this.openHelpModal();
    });
  }

  developerQuotes() {
    // Get random number for quote array
    const number = Math.floor(Math.random() * (324));
    this.appS.getQuotes().subscribe(data => {
      console.log('%c"' + data[number].text + '" - ' + data[number].from, 'background: #222; color: #FFC300  ');
    });
  }

  @HostListener('document:keydown.meta.k', ['$event'])
  openModal(e: KeyboardEvent) {
    e.preventDefault();
  }

  // @HostListener('document:keydown.shift,d')
  doSomething() {
    this.logger.log('Doing something');

  }

  @HostListener('document:keydown.shift.control.d')
  doSomethingElse() {
    this.logger.log('Doing something else');

  }

  // Rolling back dev
  onKeydown($event: any) {
    this.logger.log('Doing something', $event);
  }

  openHelpModal() {
    console.log('Template:', this.customTemplate);
    this.dialog.open(this.customTemplate, {
      width: '500px',
      data: {access_token: localStorage.getItem('access_token')}
      // data: this.hotkeys
    });
  }

  copyAccessToken() {
    console.log('Copied access token:', localStorage.getItem('access_token'));
    navigator.clipboard.writeText(localStorage.getItem('access_token')).then().catch(e => console.error(e));
  }

  copyIdToken() {
    console.log('Copied ID token:', localStorage.getItem('id_token'));
    navigator.clipboard.writeText(localStorage.getItem('id_token')).then().catch(e => console.error(e));
  }
}


export interface DialogData {
  access_token: '';
}
