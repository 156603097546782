import { Inject, Injectable, TemplateRef, ViewChild, Directive } from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventManager} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root'
})

export class AppService {
  defaults: Partial<Options> = {
    element: this.document
  };
  @ViewChild('template') customTemplate: TemplateRef<any>;
  data = [];
  hotkeys = Array.from(this.data);


  constructor(
    private http: HttpClient,
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  getQuotes() {
    // return this.http.get(`./assets/sample-data/developer-quotes.json`)
    return this.http.get(`https://gist.githubusercontent.com/b1nary/ea8fff806095bcedacce/raw/`
      + `6e6de20d7514b93dd69b149289264997b49459dd/enterpreneur-quotes.json`)
      .pipe(tap(res => {
          return {
            data: res,
          };
        })
      );
  }

  addShortcut(options: Partial<Options>) {
    const merged = {...this.defaults, ...options};
    const event = `keydown.${merged.keys}`;

    // merged.description && this.hotkeys.set(merged.keys, merged.description);

    return new Observable(observer => {
      const handler = (e) => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(
        merged.element, event, handler
      );

      return () => {
        dispose();
        // this.hotkeys.delete(merged.keys);
      };
    });
  }
}

interface Options {
  element: any;
  keys: string;
}
