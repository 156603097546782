import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-material-date-range-selection',
  templateUrl: './material-date-range-selection.component.html',
  styleUrls: ['./material-date-range-selection.component.css']
})
export class MaterialDateRangeSelectionComponent implements OnInit {
  @Output() newPlacement = new EventEmitter<string>();
  bsConfig: Partial<BsDaterangepickerConfig> = Object.assign({}, {
    containerClass: 'theme-dark-blue', placement: this.newPlacement, displayOneMonthRange: true, displayMonths: 2
  });
  faCalendar = faCalendar;

  startOfDay = moment().startOf('day');
  endOfDay = moment().endOf('day');

  startOfDayAsDate: string = moment(this.startOfDay.format()).toISOString();
  endOfDayAsDate: string = moment(this.endOfDay.format()).toISOString();

  selectedDate = [
    // this.startOfDayAsDate,
    // this.endOfDayAsDate
  ];
  @Input() placement = 'bottom';
  @Output() newDate = new EventEmitter<string[]>();
  @Input() set _preSetDate(preSetDate) {
    if (typeof preSetDate === 'string') {
      preSetDate = preSetDate.split(',');
    }
    this.logger.info('preSet Dates:', moment(preSetDate[0]).format('Do MMMM, YYYY'), '-',
      moment(preSetDate[1]).format('Do MMMM, YYYY'));
    this.selectedDate = preSetDate;
    this.logger.info('Dates (formatted)', moment(preSetDate[0]).format('dddd, MMMM Do YYYY, h:mm:ss a'), '-',
      moment(preSetDate[1]).format('dddd, MMMM Do YYYY, h:mm:ss a'));
  }
  constructor(
    private logger: NGXLogger
  ) { }

  ngOnInit() {
  }

  setDate(date) {
    // this.logger.log( 'From date-range-selection setDate: ' + date[0].toISOString());
    // this.logger.log( 'From date-range-selection setDate: ' + date[1].toISOString());

    const startOfFirstDay: string = moment(date[0]).startOf('day').local().format('YYYY-MM-DD[T]HH:mm:ss[.000]');
    // const startOfFirstDay: string = moment(date[0]).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.000Z]');
    const endOfLastDay: string = moment(date[1]).endOf('day').local().format('YYYY-MM-DD[T]HH:mm:ss[.000]');
    // const endOfLastDay: string = moment(date[1]).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss[.000Z]');
    const startDate = moment(date[0]).format('Do MMM, YYYY');
    const endDate = moment(date[1]).format('Do MMM, YYYY');

    // this.logger.log('Date range: ' + startDate + ' - ' + endDate);
    this.logger.info('Date range selected:', startDate, '-', endDate);
    this.logger.info('Date range selected:', startOfFirstDay, '-', endOfLastDay);

    this.selectedDate = [startOfFirstDay, endOfLastDay];
    this.newDate.emit(
      [startOfFirstDay, endOfLastDay]
    );
  }

}
