<router-outlet></router-outlet>
<app-page-title title="Testing"></app-page-title>
<div class="col-12">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <mat-tab-group>
          <mat-tab label="First"> Content 1</mat-tab>
          <mat-tab label="Second"> Content 2</mat-tab>
          <mat-tab label="Third"> Content 3</mat-tab>
        </mat-tab-group>
        <mat-tab-group [selectedIndex]="selectedIndex">
          <mat-tab disabled="true" *ngFor="let tab of tabs; let i=index;">
            <ng-template mat-tab-label>
              {{tab.name}}
            </ng-template>
            {{tab.name}} content
          </mat-tab>
        </mat-tab-group>
      </mat-card>
      <button mat-raised-button color="primary" (click)="previousStep()">Previous</button>
      <button mat-raised-button color="primary" (click)="nextStep()">Next</button>
    </div>
  </div>
</div>
