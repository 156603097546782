import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../models/get-api-reply.model';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../config.service';

@Injectable()
export class UserPermissionsServiceV2 {
  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  create(new_user_permissions) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}user_permissions`,
      new_user_permissions,
      this.conf.getHttpOptions()
    );
  }

  update(id, new_user_permissions) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}user_permissions?id=eq.${id}`,
      new_user_permissions,
      this.conf.getHttpOptions()
    );
  }
}
