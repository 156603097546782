import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../models/get-api-reply.model';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../config.service';

@Injectable()
export class UserServiceV2 {
  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  get(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}users?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }
}
