<!--<hr>-->
<nav class="navbar navbar-expand-xl navbar-dark fixed-top">
  <!--<nav class="navbar navbar-expand-lg">-->
  <!-- Logo -->
  <a class="navbar-brand" [routerLink]="'/dashboard'">
    <!--  <a class="navbar-brand" [routerLink]="'/transactions/dockets'">-->
    <img src="https://i.imgur.com/57yW7So.png" height="45px" alt="Tin Can Logo">
  </a>
  <!-- Menu Toggle for mobile devices -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
          aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <!-- Menubar Items -->
  <div class="collapse navbar-collapse" id="navbarNav" [collapse]="isCollapsed" [isAnimated]="true">
    <ul class="navbar-nav mr-auto" *ngIf="userPerms != null && auth.isAuthenticated()">
      <!--      <img [src]="user_image">-->
      <!-- ########### -->
      <!-- Sales -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.salesItems.sales">
        <button #button mat-button [matMenuTriggerFor]="sales" #salesTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(salesTrigger)" (mouseleave)="buttonLeave(salesTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-shopping-cart"></span>
          <span>Sales
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #sales="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(salesTrigger, button)">
        <button mat-menu-item [matMenuTriggerFor]="dockets" *ngIf="perms.salesItems.dockets">Dockets</button>
        </span>
        </mat-menu>
        <mat-menu #dockets="matMenu">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(salesTrigger,dockets, button)">
        <!-- Dockets -->
        <button mat-menu-item>
          <a [routerLink]="'/transactions/dockets'" class="nav-link">Dockets</a>
        </button>
          <!-- Docket Reprint -->
        <button mat-menu-item>
          <a [routerLink]="'/transactions/docket-reprint'" class="nav-link ">Docket Reprint</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Orders -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.orders">
        <button #button mat-button [matMenuTriggerFor]="orders" #ordersTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(ordersTrigger)" (mouseleave)="buttonLeave(ordersTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-file-invoice"></span>
          <span>Orders
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #orders="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(ordersTrigger, button)">
        <button mat-menu-item *ngIf="userPerms.orders.service_orders">
          <a [routerLink]="'/orders/orders'" class="nav-link ">Orders</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.orders.standing_orders">
          <a [routerLink]="'/orders/standing-orders'" class="nav-link">Standing Orders</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.orders.special_orders">
          <a [routerLink]="'/orders/additional-orders'" class="nav-link ">Special Orders</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.orders.service_orders">
          <a [routerLink]="'/orders/service-orders'" class="nav-link ">Service Orders</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Production -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.production">
        <button #button mat-button [matMenuTriggerFor]="production" #productionTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(productionTrigger)" (mouseleave)="buttonLeave(productionTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-industry"></span>
          <span>Production
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #production="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(productionTrigger, button)">
        <button mat-menu-item *ngIf="userPerms.production.production_list">
          <a [routerLink]="'/production/manage-production-lists'" class="nav-link text-small">Production
            Lists</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.production.create_production_list">
          <a [routerLink]="'/production/generate-production-lists'" class="nav-link text-small">Create Production
            Lists</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.production.production_groups">
          <a [routerLink]="'/production/production-groups'" class="nav-link text-small">Production Groups</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.production.production_inputs">
          <a [routerLink]="'/production/input'" class="nav-link text-small">Production Inputs</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.production.production_process">
          <a [routerLink]="'/production/formulae'" class="nav-link text-small">Production Process</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.production.batch_control">
          <a [routerLink]="'/production/batch'" class="nav-link text-small">Batch Control</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Stock -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.stockItems.stock">
        <button #button mat-button [matMenuTriggerFor]="inventory" #inventoryTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(inventoryTrigger)" (mouseleave)="buttonLeave(inventoryTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-cubes"></span>
          <span>Stock
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #inventory="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(inventoryTrigger, button)">
        <button mat-menu-item *ngIf="userPerms.stock.stock_level_alert">
          <a [routerLink]="'/inventory/stock-management'" class="nav-link ">Stock Management</a>
        </button>
<!--        <button mat-menu-item *ngIf="userPerms.stock.stock_transfer" [matMenuTriggerFor]="stockSearch">Stock Search</button>-->
<!--        <button mat-menu-item *ngIf="userPerms.stock.stock_level_alert">-->
<!--          <a [routerLink]="'/inventory/stock'" class="nav-link ">Stock</a>-->
<!--        </button>-->
<!--        <button mat-menu-item *ngIf="userPerms.stock.stock_level_alert">-->
<!--          <a [routerLink]="'/inventory/stock-location'" class="nav-link ">Stock Location</a>-->
<!--        </button>-->
        <button mat-menu-item *ngIf="userPerms.stock.stock_level_alert">
          <a [routerLink]="'/inventory/stock-count-alert'" class="nav-link">Stock Level Alert</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.stock.stock_search.storage_area_search">
          <a [routerLink]="'/inventory/stock-transfer'" class="nav-link text-small pb-0 ">Stock Transfer</a>
        </button>
        </span>
        </mat-menu>
        <mat-menu #stockSearch="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(inventoryTrigger,stockSearch, button)">
        <button mat-menu-item *ngIf="userPerms.stock.stock_search.production_location">
          <a [routerLink]="'/inventory/stock-search/product-location'" class="nav-link text-small pb-0 ">Product Location</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.stock.stock_search.location_search">
          <a [routerLink]="'/inventory/stock-search/location-search'"
             class="nav-link text-small pb-0 ">Location Search</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.stock.stock_search.storage_area_search">
          <a [routerLink]="'/inventory/stock-search/storage-area-search'" class="nav-link text-small pb-0 ">Storage Area Search</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Logistics -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.logisticsItems.logistics">
        <button #button mat-button [matMenuTriggerFor]="logistics" #logisticsTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(logisticsTrigger)" (mouseleave)="buttonLeave(logisticsTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-truck"></span>
          <span>Logistics
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #logistics="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(logisticsTrigger, button)">
        <button mat-menu-item [matMenuTriggerFor]="suppliers" *ngIf="perms.logisticsItems.suppliers">Suppliers</button>
        <button mat-menu-item [matMenuTriggerFor]="deliveries" *ngIf="perms.logisticsItems.delivery">Deliveries</button>
        <button mat-menu-item [matMenuTriggerFor]="dispatch" *ngIf="perms.logisticsItems.dispatch">Dispatch</button>
        </span>
        </mat-menu>
        <!-- Suppliers -->
        <mat-menu #suppliers="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(logisticsTrigger,suppliers, button)">
        <button mat-menu-item *ngIf="userPerms.logistics.suppliers.suppliers_list">
          <a [routerLink]="'/logistics/suppliers'" class="nav-link text-small pb-0 ">Suppliers List</a>
        </button></span>
        </mat-menu>
        <!-- Deliveries -->
        <mat-menu #deliveries="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(logisticsTrigger,deliveries, button)">
        <button mat-menu-item *ngIf="userPerms.logistics.delivery.delivery_lists">
          <a [routerLink]="'/logistics/delivery-lists'" class="nav-link ">Delivery Lists</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.logistics.delivery.create_delivery_list">
          <a [routerLink]="'/logistics/delivery-lists/create'" class="nav-link ">Create Delivery Lists</a>
        </button></span>
        </mat-menu>
        <!-- Dispatch -->
        <mat-menu #dispatch="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(logisticsTrigger,dispatch, button)">
        <button mat-menu-item *ngIf="userPerms.logistics.dispatch.dispatch_groups">
          <a [routerLink]="'/logistics/dispatch-group'" class="nav-link">Dispatch Groups</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.logistics.dispatch.create_dispatch_lists">
          <a [routerLink]="'/logistics/new-dispatch-list'" class="nav-link">Create Dispatch Lists</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.logistics.dispatch.edit_dispatch_lists">
          <a [routerLink]="'/logistics/manage-dispatch-list'" class="nav-link ">Edit Dispatch Lists</a>
        </button></span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Finance -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.financeItems.finance">
        <button #button mat-button [matMenuTriggerFor]="finance" #financeTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(financeTrigger)" (mouseleave)="buttonLeave(financeTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-coins"></span>
          <span>Finance
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #finance="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(financeTrigger, button)">
        <button mat-menu-item [matMenuTriggerFor]="invoices" *ngIf="perms.financeItems.documents">Documents</button>
        <button mat-menu-item [matMenuTriggerFor]="discounts" *ngIf="perms.financeItems.discounts">Discounts</button>
        <button mat-menu-item [matMenuTriggerFor]="exports" *ngIf="perms.financeItems.exports">Exports</button>
        <button mat-menu-item [matMenuTriggerFor]="payments" *ngIf="perms.financeItems.discounts">Payments</button>
        </span>
        </mat-menu>
        <!-- Documents -->
        <mat-menu #invoices="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(financeTrigger,invoices, button)">
          <button mat-menu-item *ngIf="userPerms.finance.documents.create_invoice">
            <a [routerLink]="'/finance/documents/invoices/create'" class="nav-link">Create Invoices</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.documents.view_invoice">
            <a [routerLink]="'/finance/documents/invoices'" class="nav-link ">View Invoices</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.documents.create_statements">
            <a [routerLink]="'/finance/documents/statements/customer'" class="nav-link">Customer Statements</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.documents.create_statements">
            <a [routerLink]="'/finance/documents/statements/supplier'" class="nav-link">Supplier Statements</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.documents.create_credit_notes">
            <a [routerLink]="'/finance/documents/credit-notes'" class="nav-link">Credit Vouchers</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.documents.create_credit_notes">
            <a [routerLink]="'/finance/documents/credit-notes-notes'" class="nav-link">Credit Notes</a>
          </button>
        </span>
        </mat-menu>
        <!-- Discounts -->
        <mat-menu #discounts="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(financeTrigger,discounts, button)">
          <button mat-menu-item *ngIf="userPerms.finance.discounts.product_discounts">
            <a [routerLink]="'/finance/discounts/product-discounts'" class="nav-link ">Product Discounts</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.discounts.customer_discounts">
            <a [routerLink]="'/finance/discounts/customer-discounts'" class="nav-link ">Customer Discounts</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.discounts.product_quantity_offers">
            <a [routerLink]="'/finance/discounts/product-qty-deal'" class="nav-link ">Product Quantity Deal</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.discounts.bulk_discounts">
            <a [routerLink]="'/finance/discounts/bulk-discounts'" class="nav-link ">Bulk Discounts</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.discounts.billing_discounts">
            <a [routerLink]="'/finance/discounts/billing-discounts'" class="nav-link ">Billing Discounts</a>
          </button>
        </span>
        </mat-menu>
        <!-- Exports -->
        <mat-menu #exports="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(financeTrigger,exports, button)">
<!--          <button mat-menu-item *ngIf="userPerms.finance.exports.export_invoices">-->
<!--            <a [routerLink]="'/finance/exports/account-exports/package'" class="nav-link ">Export Invoices</a>-->
<!--          </button>-->
<!--          <button mat-menu-item *ngIf="userPerms.finance.exports.review_invoices">-->
<!--            <a [routerLink]="'/finance/exports/account-exports/manage'" class="nav-link">Review Exports</a>-->
<!--          </button>-->
          <button mat-menu-item *ngIf="userPerms.finance.exports.customer_exports">
            <a [routerLink]="'finance/exports/customer-exports'" class="nav-link">Customer Exports</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.exports.product_exports">
            <a [routerLink]="'finance/exports/product-exports'" class="nav-link">Product Exports</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.exports.custom_exports">
            <a [routerLink]="'/finance/exports/account-exports'" class="nav-link ">Account Package Exports</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.exports.edi_exports">
            <a [routerLink]="'/finance/exports/edi-exports'" class="nav-link ">EDI Exports</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.exports.custom_exports">
            <a [routerLink]="'/finance/exports/csv-exports'" class="nav-link ">Custom Exports</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.exports.custom_exports">
            <a [routerLink]="'/finance/exports/email-exports'" class="nav-link ">Email Billing Exports</a>
          </button>
        </span>
        </mat-menu>
        <!-- payments -->
        <mat-menu #payments="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(financeTrigger,payments, button)">
          <button mat-menu-item *ngIf="userPerms.finance.payments.incoming">
            <a [routerLink]="'/finance/payments/incoming'" class="nav-link ">Receipts</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.finance.payments.outgoing">
            <a [routerLink]="'/finance/payments/outgoing'" class="nav-link ">Payments</a>
          </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Services -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.services">
        <button #button mat-button [matMenuTriggerFor]="services" #servicesTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(servicesTrigger)" (mouseleave)="buttonLeave(servicesTrigger, button)"
                style="z-index:1050" class="process">
          <span class="icon fa fa-user"></span>
          <span>Services
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #services="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(servicesTrigger, button)">
        <button mat-menu-item *ngIf="userPerms.services.service_list">
          <a [routerLink]="'/services/services-list'" class="nav-link">Service List</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.services.job_report_builder">
          <a [routerLink]="'/services/job-report-builder'" class="nav-link">Job Report Builder</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.services.job_reports">
          <a [routerLink]="'/services/job-reports'" class="nav-link">Job Reports</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.services.create_service_form">
          <a [routerLink]="'/orders/service-orders/create'" class="nav-link">Create Service Form</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Customers -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.customers">
        <button #button mat-button [matMenuTriggerFor]="customers" #customersTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(customersTrigger)" (mouseleave)="buttonLeave(customersTrigger, button)"
                style="z-index:1050" class="assignment">
          <span class="icon fa fa-user"></span>
          <span>Customers
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #customers="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(customersTrigger, button)">
        <button mat-menu-item *ngIf="userPerms.customers.customers">
          <a [routerLink]="'/customers/customers-list'" class="nav-link">Customers</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.customers.customer_group_update">
          <a [routerLink]="'/customers/customer-group-update'" class="nav-link">Customer Group Update</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.customers.customers_on_routes">
          <a [routerLink]="'/customers/customers-on-route'" class="nav-link">Customers on Route</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.customers.customer_specific_prices">
          <a [routerLink]="'/customers/customer-specific-prices'" class="nav-link">Customer Specific Prices</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.customers.customer_specific_price_group_update">
          <a [routerLink]="'/customers/customer-specific-price-group-update'" class="nav-link">Customer Specific Price Group Update</a>
        </button>
        <button mat-menu-item *ngIf="userPerms.customers.inactive_customers">
          <a [routerLink]="'/admin/customer-settings/inactive-customers'" class="nav-link">Inactive Customers</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/customers/route-mappings'" class="nav-link">Route Mappings</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Products -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.products">
        <button #button mat-button [matMenuTriggerFor]="products" #productsTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(productsTrigger)" (mouseleave)="buttonLeave(productsTrigger, button)"
                style="z-index:1050" class="assignment">
          <span class="icon fa fa-boxes"></span>
          <span>Products
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #products="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(productsTrigger, button)">
        <button mat-menu-item>
          <a [routerLink]="'/products/products-list'" class="nav-link">Products</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/mass-product-update'" class="nav-link ">Multiple Product Update</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/product-price-list'" class="nav-link ">Price List</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/products/mass-price-update'" class="nav-link ">Multiple Price Update</a>
        </button>
        <button mat-menu-item>
          <a [routerLink]="'/admin/product-settings/inactive-products'" class="nav-link ">Inactive Products</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- ########### -->
      <!-- Admin -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="perms.adminItems.admin">
        <button #button mat-button [matMenuTriggerFor]="admin" #adminTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(adminTrigger)" (mouseleave)="buttonLeave(adminTrigger, button)"
                style="z-index:1050" class="settings">
          <span class="icon fa fa-user-cog"></span>
          <span>Admin
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #admin="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(adminTrigger, button)">
        <button mat-menu-item [matMenuTriggerFor]="adminOrganisation"
                *ngIf="perms.adminItems.organisation_settings">Organisation Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminFinancial"
                *ngIf="perms.adminItems.financial_settings">Financial Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminCustomer"
                *ngIf="perms.adminItems.customer_settings">Customer Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminProducts"
                *ngIf="perms.adminItems.product_settings">Product Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminStorage"
                *ngIf="perms.adminItems.storage_settings">Storage Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminHandheld"
                *ngIf="perms.adminItems.handheld_settings">Handheld Settings</button>
        <button mat-menu-item [matMenuTriggerFor]="adminOther"
                *ngIf="perms.adminItems.other_settings">Other Settings</button>
        </span>
        </mat-menu>
        <!-- Organisation Settings -->
        <mat-menu #adminOrganisation="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminOrganisation, button)">
          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.company_details">
            <a [routerLink]="'/admin/organisation-settings/company-details'" class="nav-link">Company Details</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.account_package_setup">
            <a [routerLink]="'/admin/organisation-settings/account-package-setup'" class="nav-link">Account Package
              Setup</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.billing_details">
            <a [routerLink]="'/admin/organisation-settings/billing-details'" class="nav-link">Billings Details</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.logo_upload">
            <a [routerLink]="'/admin/organisation-settings/logo-upload'" class="nav-link">Logo Upload</a>
          </button>
<!--          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.logo_upload">-->
<!--            <a [routerLink]="'/admin/organisation-settings/export-options'" class="nav-link">Export Options</a>-->
<!--          </button>-->
          <button mat-menu-item *ngIf="userPerms.admin.organisation_settings.logo_upload">
            <a [routerLink]="'/admin/organisation-settings/email-billing'" class="nav-link">Email Billing</a>
          </button>
        </span>
        </mat-menu>
        <!-- Financial Settings -->
        <mat-menu #adminFinancial="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminFinancial, button)">
          <button mat-menu-item *ngIf="perms.adminItems.financial_settings">
            <a [routerLink]="'/admin/financial-settings/bank-details'" class="nav-link">Bank Details</a>
          </button>
          <button mat-menu-item>
            <a [routerLink]="'/admin/financial-settings/account-details'" class="nav-link">Account Details</a>
          </button>
          <button mat-menu-item>
            <a [routerLink]="'/admin/financial-settings/vat-rates'" class="nav-link ">Vat Rates</a>
          </button>
        </span>
        </mat-menu>
        <!-- Customer Settings -->
        <mat-menu #adminCustomer="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminCustomer, button)">
          <button mat-menu-item *ngIf="perms.adminItems.customer_settings">
            <a [routerLink]="'/admin/customer-settings/depots'" class="nav-link ">Depots</a>
          </button>
          <button mat-menu-item *ngIf="perms.adminItems.customer_settings">
            <a [routerLink]="'/admin/customer-settings/customer-types'" class="nav-link ">Customer Types</a>
          </button>
          <button mat-menu-item *ngIf="perms.adminItems.customer_settings">
            <a [routerLink]="'/admin/customer-settings/routes'" class="nav-link ">Routes</a>
          </button>
          <button mat-menu-item *ngIf="perms.adminItems.customer_settings">
            <a [routerLink]="'/admin/customer-settings/billing-frequency'" class="nav-link ">Billing Frequency</a>
          </button>
        </span>
        </mat-menu>
        <!-- Product Settings -->
        <mat-menu #adminProducts="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminProducts, button)">
          <button mat-menu-item *ngIf="userPerms.admin.product_settings.product_categories">
            <a [routerLink]="'/admin/product-settings/product-categories'" class="nav-link ">Product Categories</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.product_settings.sub_product_categories">
            <a [routerLink]="'/admin/product-settings/sub-product-categories'" class="nav-link ">Sub-Product
              Categories</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.product_settings.quantifiers">
            <a [routerLink]="'/admin/product-settings/quantifiers'" class="nav-link ">Quantifiers</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.product_settings.create_nominal_codes">
            <a [routerLink]="'/admin/product-settings/nominal-codes'" class="nav-link ">Nominal Codes</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.product_settings.create_nominal_codes">
            <a [routerLink]="'/admin/product-settings/auto-batch'" class="nav-link ">Auto-Batch</a>
          </button>
        </span>
        </mat-menu>
        <!-- Storage Settings -->
        <mat-menu #adminStorage="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminStorage, button)">
          <button mat-menu-item *ngIf="userPerms.admin.storage_settings.storage_locations">
            <a [routerLink]="'/admin/storage-settings/storage-locations'" class="nav-link ">Storage Locations</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.storage_settings.storage_location_types">
            <a [routerLink]="'/admin/storage-settings/storage-location-types'" class="nav-link ">Storage Location
              Types</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.storage_settings.storage_areas">
            <a [routerLink]="'/admin/storage-settings/storage-areas'" class="nav-link ">Storage Areas</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.storage_settings.storage_area_types">
            <a [routerLink]="'/admin/storage-settings/storage-area-types'" class="nav-link ">Storage Area Types</a>
          </button>
        </span>
        </mat-menu>
        <!-- Handheld Settings -->
        <mat-menu #adminHandheld="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminHandheld, button)">
          <button mat-menu-item *ngIf="userPerms.admin.handheld_settings.create_handhelds">
            <a [routerLink]="'/admin/organisation-settings/handheld-settings/handhelds'" class="nav-link ">Create Handheld</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.handheld_settings.assign_handhelds_to_routes">
            <a [routerLink]="'/admin/organisation-settings/handheld-settings/hhtoroute'" class="nav-link ">Assign Handhelds to Route</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.handheld_settings.user_controls">
            <a [routerLink]="'/admin/organisation-settings/handheld-settings/hhfeaturevisibility'" class="nav-link ">User Controls</a>
          </button>
        </span>
        </mat-menu>
        <!-- Other Settings -->
        <mat-menu #adminOther="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(adminTrigger,adminOther, button)">
          <button mat-menu-item *ngIf="userPerms.admin.other_settings.edi_settings">
            <a [routerLink]="'/admin/other/edi-settings'" class="nav-link ">EDI Settings</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.admin.other_settings.commissions">
            <a [routerLink]="'/admin/commissions'" class="nav-link ">Commissions</a>
          </button>
        </span>
        </mat-menu>
      </li>
      <!-- Traceability -->
      <li class="nav-item" *ngIf="userPerms.traceability.barcodes">
        <button #button mat-button [matMenuTriggerFor]="traceability" #traceabilityTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(traceabilityTrigger)" (mouseleave)="buttonLeave(traceabilityTrigger, button)"
                style="z-index:1050" class="settings">
          <span class="icon fa fa-user"></span>
          <span>Traceability
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #traceability="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(traceabilityTrigger, button)">
        <button mat-menu-item>
          <a [routerLink]="'/interaction/barcodes'" class="nav-link">Barcodes</a>
        </button>
        </span>
        </mat-menu>
      </li>
      <!-- Reports -->
      <li class="nav-item" *ngIf="perms.financeItems.finance">
        <button #button mat-button [matMenuTriggerFor]="reports" #reportsTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(reportsTrigger)" (mouseleave)="buttonLeave(reportsTrigger, button)"
                style="z-index:1050" class="performance">
          <span class="icon fa fa-coins"></span>
          <span>Reports
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #reports="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(reportsTrigger, button)">
        <button mat-menu-item [matMenuTriggerFor]="salesReport"
                *ngIf="perms.financeItems.documents">Sales Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="productionReport"
                *ngIf="userPerms.reports.production_reports.create">Production Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="servicingReport" *ngIf="userPerms.reports.servicing_reports.create">Service Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="stockReport"
                *ngIf="userPerms.reports.stock_reports.create">Stock Reports</button>
        <button mat-menu-item [matMenuTriggerFor]="financeReport"
                *ngIf="userPerms.reports.financial_reports.create">Financial Reports</button>
          <button mat-menu-item *ngIf="userPerms.reports.agent_commission_report.create">
            <a [routerLink]="'/transactions/commissions-view'" class="nav-link">Agent Commissions Report</a>
          </button>
          <!--        <button mat-menu-item [matMenuTriggerFor]="serviceReport" *ngIf="userPerms.reports.servicing_reports.create">Service Reports</button>-->
        </span>
        </mat-menu>
        <!-- Sales Report -->
        <mat-menu #salesReport="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,salesReport, button)">
          <button mat-menu-item *ngIf="userPerms.reports.sales_reports.create">
            <a [routerLink]="'/reports/sales/generate'" class="nav-link">Create</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.sales_reports.saved">
            <a [routerLink]="'/reports/sales/saved'" class="nav-link ">Saved</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.sales_reports.comparison">
            <a [routerLink]="'/reports/sales/comparison'" class="nav-link ">Comparison</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.sales_reports.comparison">
            <a [routerLink]="'/reports-old/sales-reports/comparison-sales-report'" class="nav-link">Comparison (old)</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.sales_reports.summary">
            <a [routerLink]="'/reports/sales/summary'" class="nav-link">Summary</a>
          </button>
        </span>
        </mat-menu>
        <!-- Production Report -->
        <mat-menu #productionReport="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,productionReport, button)">
          <button mat-menu-item *ngIf="userPerms.reports.production_reports.create">
            <a [routerLink]="'reports/production/generate'" class="nav-link">Create</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.production_reports.saved">
            <a [routerLink]="''" class="nav-link ">Saved</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.production_reports.comparison">
            <a [routerLink]="''" class="nav-link">Comparison</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.production_reports.summary">
            <a [routerLink]="''" class="nav-link">Summary</a>
          </button>
        </span>
        </mat-menu>
        <!-- Service Report -->
        <mat-menu #servicingReport="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,servicingReport, button)">
<!--          <button mat-menu-item *ngIf="userPerms.reports.servicing_reports.create">-->
          <!--            <a [routerLink]="'/generate-service-report'" class="nav-link">Create</a>-->
          <!--          </button>-->
          <button mat-menu-item *ngIf="userPerms.reports.servicing_reports.create">
            <a [routerLink]="'/reports/service/generate'" class="nav-link">Create</a>
          </button>
        </span>
        </mat-menu>
        <!-- Stock Report -->
        <mat-menu #stockReport="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,stockReport, button)">
          <button mat-menu-item *ngIf="userPerms.reports.stock_reports.create">
            <a [routerLink]="'reports/stock/generate'" class="nav-link">Create</a>
          </button>
        </span>
        </mat-menu>
        <!-- Financial Report -->
        <mat-menu #financeReport="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,financeReport, button)">
          <button mat-menu-item *ngIf="userPerms.reports.financial_reports.create">
            <a [routerLink]="''" class="nav-link">Create</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.financial_reports.saved">
            <a [routerLink]="''" class="nav-link ">Saved</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.financial_reports.comparison">
            <a [routerLink]="''" class="nav-link">Comparison</a>
          </button>
          <button mat-menu-item *ngIf="userPerms.reports.financial_reports.summary">
            <a [routerLink]="''" class="nav-link">Summary</a>
          </button>
        </span>
        </mat-menu>
        <!-- Servicing Report -->
        <!--        <mat-menu #serviceReport="matMenu" [overlapTrigger]="false">-->
        <!--        <span (mouseenter)="menu2enter()" (mouseleave)="menu2Leave(reportsTrigger,serviceReport, button)">-->
        <!--&lt;!&ndash;          <button mat-menu-item *ngIf="userPerms.reports.servicing_reports.create">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <a [routerLink]="'/generate-service-report'" class="nav-link">Create</a>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </button>&ndash;&gt;-->
        <!--          <button mat-menu-item *ngIf="userPerms.reports.servicing_reports.create">-->
        <!--            <a [routerLink]="'/reports/service/generate'" class="nav-link">Create</a>-->
        <!--          </button>-->
        <!--        </span>-->
        <!--        </mat-menu>-->
      </li>
      <!-- ########### -->
      <!-- Dashboard -->
      <!-- ########### -->
      <li class="nav-item" *ngIf="userPerms.dashboard.sales">
        <button #button mat-button [matMenuTriggerFor]="dashboard" #dashboardTrigger="matMenuTrigger"
                (mouseenter)="buttonEnter(dashboardTrigger)" (mouseleave)="buttonLeave(dashboardTrigger, button)"
                style="z-index:1050" class="performance">
          <span class="icon fa fa-chart-pie"></span>
          <span>Dashboard
            <i class="fa fa-chevron-down"></i>
            </span>
        </button>
        <mat-menu #dashboard="matMenu" [overlapTrigger]="false">
        <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(dashboardTrigger, button)">
        <button mat-menu-item>
          <a [routerLink]="'/dashboard'" class="nav-link">Sales</a>
        </button>
        </span>
        </mat-menu>
      </li>
    </ul>
    <!-- ########### -->
    <!-- Log in / log out -->
    <!-- ########### -->
    <ul class="navbar-nav" *ngIf="auth.isAuthenticated()">
      <li class="nav-item">
        <a class="nav-link navbar-brand" *ngIf="auth.isAuthenticated()">
<!--          <img style="height: 25px;" *ngIf="base64textString" src={{base64textString}} alt="" id="organisationLogo">-->
          <img *ngIf="base64textString != ''" [src]="base64textString" alt="Logo" height="25px">
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="userDropdown">{{name}}</a>
      </li>
      <mat-menu #userDropdown="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <mat-icon>mail_outline</mat-icon>
          <span>{{email}}</span>
        </button>
        <button mat-menu-item (click)="auth.logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Log Out</span>
        </button>
      </mat-menu>
    </ul>
    <ul class="navbar-nav ml-auto" *ngIf="!auth.isAuthenticated()">
      <li class="nav-item" (click)="auth.login()">
        <a class="nav-link">
          <fa-icon [icon]="faSignIn"></fa-icon>
          Log in</a>
      </li>
    </ul>
  </div>
</nav>
