
<mat-form-field appearance="outline">
  <mat-label *ngIf="data.length != 0">{{label}}</mat-label>
  <mat-label *ngIf="data.length == 0">
    <div class="select-placeholder-container">
          <span>Loading {{label}}s...
          <mat-spinner class="spinner" diameter="20"></mat-spinner></span>
    </div>
  </mat-label>
  <mat-select [formControl]="itemCtrl" [(ngModel)]="selectedItems" [multiple]="!singleSelection" disableOptionCentering
              (selectionChange)="itemSelected($event)" [disabled]="disabled || data.length == 0" (click)="checkArray()">
    <mat-option>
      <ngx-mat-select-search
        [showToggleAllCheckbox]="!singleSelection"
        (toggleAll)="toggleSelectAll($event)"
        [formControl]="itemFilterCtrl"
        noEntriesFoundLabel="No matching category found!"
        placeholderLabel="Search">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{item[target]}}
    </mat-option>
  </mat-select>
</mat-form-field>
