import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {version} from '../../../../../package.json';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public version: string = version;
  year = '';

  constructor(
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {
    this.logger.info('Version:', version);
    this.year = moment(new Date()).format('YYYY');
  }

}
