<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content *ngIf="data.message">{{data.message}}</div>
<div mat-dialog-content *ngIf="data.warning" class="warning"><mat-icon>error</mat-icon> {{data.warning}}</div>

<div mat-dialog-actions>
  <div class="row">
    <div class="col-6">
      <button mat-raised-button color="warn" class="col-12" [mat-dialog-close]="confirm()">Delete <mat-icon>delete</mat-icon></button>
    </div>
    <div class="col-6">
      <button mat-raised-button color="primary" class="col-12" (click)="cancel()">Cancel <mat-icon>block</mat-icon></button>
    </div>
  </div>
</div>
