import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TestingComponent} from './testing.component';
import {TestingRoutingModule} from './testing-routing.module';
import {RouterModule} from '@angular/router';
import {MenuComponent} from '../core/utils/menu/menu.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CoreModule} from '../core/core.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    TestingComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    CollapseModule,
    FontAwesomeModule,
    MatMenuModule,
    CoreModule,
    MatTabsModule,
    MatCardModule,
  ],
  exports: [
    TestingComponent,
    TestingRoutingModule,
    MenuComponent
  ]
})
export class TestingModule { }
